import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from '../public/keycloak';

import { BrowserRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";

import "primereact/resources/themes/bootstrap4-light-blue/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import Main from "./components/main/Main";

function App() {
  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={{onLoad: 'login-required'}} >
      <BrowserRouter>
        <Container>
          <Main />
        </Container>
    </BrowserRouter>
    </ReactKeycloakProvider>
  )
}

export default App;