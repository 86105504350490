import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { TabPanel, TabView } from 'primereact/tabview';
import IncomeReport from '../../components/reports/IncomeReport';

export default function Reports() {
    const navigate = useNavigate();
    return (
        <>
            <nav className="mt20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">Reports</li>
				</ol>
			</nav>
            <div className="title">
                Reports
            </div>
            <div className="mt20">
                <TabView>
                    <TabPanel header="Month Summary">
                        <IncomeReport />
                    </TabPanel>
                </TabView>
            </div>
        </>
    )
}