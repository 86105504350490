import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';
import TagDisplay from '../tags/TagDisplay';
import { parseISO } from 'date-fns';
import { sortFunction } from '../../utils/functions';

export default function CreateEditTransaction({ transaction, account, category, payee, visible, hide, displayToast }) {
     // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const [ payees, setPayees ] = React.useState([]);
    const [ categories, setcategories ] = React.useState([]);
    const [ accounts, setAccounts ] = React.useState([]);
    const [ tagText, setTagText ] = React.useState();
    const [ tags, setTags ] = React.useState([]);
    const [ currentTime, setCurrentTime ] = React.useState();
	const axiosInstance = useAxios();

    const handleTagSelect = (tag) => {
        setTags(tags.filter(t => t.text !== tag.text));
    }

    const handleTagBlur = () => {
        if (!tagText || tagText === '') return;
        const newTag = {
            text: tagText
        }
        setTags([...tags, newTag]);
        setTagText('');
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: transaction ? transaction.description : '',
            amount: transaction ? transaction.amount : '0.00',
            account: transaction ? accounts.find(a => a.name === transaction.accountName) : account ? account : null,
            category: transaction ? categories.find(c => c.name === transaction.categoryName) : category ? category : null,
            payee: transaction ? payees.find(p => p.name === transaction.payeeName) : payee ? payee : null,
            userCreateTime: transaction ? parseISO(transaction.ISOtime) : currentTime
        },
        validate: (data) => {
            let errors = {};

            if (!data.description) {
                errors.description = 'Description is required.';
            }
            if (!data.amount) {
                errors.amount = 'Amount is required.';
            }

            if (!data.account) {
                errors.account = "Account is required.";
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.transactionId = transaction?.transactionId;
            data.accountFromId = data.account.accountId;
            data.categoryId = data.category?.categoryId !== undefined ? data.category?.categoryId : null;
            data.payeeId = data.payee?.payeeId !== undefined ? data.payee?.payeeId : null;
            data.tags = tags;
            axiosInstance.current.post("/transactions", data).then(() => {
                formik.resetForm();
                displayToast(true, "Transaction saved!")
            })
            .catch((err) => {
                console.error(err);
                displayToast(false, "Error while saving transaction.");
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    React.useEffect(() => {
        axiosInstance.current.get("/accounts").then((response) => {
            if (response.data) {
                const fav = response.data.filter(a => a.favorite).sort(sortFunction);
                const non = response.data.filter(a => !a.favorite).sort(sortFunction);
                setAccounts([...fav, ...non]);
            }
        })
        axiosInstance.current.get("/categories").then((response) => {
            if (response.data) {
                const fav = response.data.filter(c => c.favorite).sort(sortFunction);
                const non = response.data.filter(c => !c.favorite).sort(sortFunction);
                setcategories([{name: 'None'}, ...fav, ...non]);
            }
        })
        axiosInstance.current.get("/payees").then((response) => {
            if (response.data) {
                const fav = response.data.filter(p => p.favorite).sort(sortFunction);
                const non = response.data.filter(p => !p.favorite).sort(sortFunction);
                setPayees([{name: 'None'}, ...fav, ...non]);
            }
        })
        setCurrentTime(new Date());
        transaction ? setTags(transaction.tags) : setTags([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transaction, visible])

    return (
        <Dialog header={transaction ? 'View/Edit Transaction' : 'Create Transaction'} visible={visible} onHide={hide}>
			<div className="flex justify-content-center dialog">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="description" name="description" value={formik.values.description} onChange={formik.handleChange} autoFocus autoComplete="off" className={classNames({ 'p-invalid': isFormFieldValid('description') })}/>
                                <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>Description *</label>
                            </span>
                            {getFormErrorMessage('description')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} id="amount" name="amount" value={formik.values.amount} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('amount') })}/>
                                <label htmlFor="amount" className={classNames({ 'p-error': isFormFieldValid('amount') })}>Amount *</label>
                            </span>
                            {getFormErrorMessage('amount')}
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="tag" name="tag" value={tagText} onChange={(e) => setTagText(e.target.value)} placeholder="New Tag" onBlur={handleTagBlur}/>
                            </span>
                        </div>
                        <div className="field m20">
                            <TagDisplay tags={tags} removable={true} selectTag={handleTagSelect} />
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Dropdown id="account" name="account" value={formik.values.account} onChange={formik.handleChange} options={accounts} optionLabel="name" className={classNames({'p-invalid' : isFormFieldValid('account')})} />
                                <label htmlFor="account" className={classNames({'p-invalid' : isFormFieldValid('account')})}>Account *</label>
                            </span>
                            {getFormErrorMessage('account')}
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Dropdown id="category" name="category" value={formik.values.category} onChange={formik.handleChange} options={categories} optionLabel="name" />
                                <label htmlFor="payee">Category</label>
                            </span>
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Dropdown id="payee" name="payee" value={formik.values.payee} onChange={formik.handleChange} options={payees} optionLabel="name" />
                                <label htmlFor="payee">Payee</label>
                            </span>
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Calendar id="userCreateTime" name="userCreateTime" value={formik.values.userCreateTime} onChange={formik.handleChange} showTime hourFormat='12' />
                            </span>
                        </div>
                        <div className="flex-row justify-content-center mt20">
                            {transaction?
                            <Button style={{ margin: 10 }} type="submit" label="Save Transaction" loading={saving} icon='pi pi-save'/>
                            :
                            <Button style={{ margin: 10 }} type="submit" label="Create Transaction" loading={saving} icon='pi pi-plus'/>
                            }
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
    )


}