import React from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useAxios } from '../../../../utils/hooks.ts';

function JoinGroupComponent(props) {
    const [ groupId, setGroupId ] = React.useState("");
    const [ saving, setSaving ] = React.useState(false);
    const axiosInstance = useAxios();

    const joinGroup = () => {
        setSaving(true);
        axiosInstance.current.put("/groups/join/" + groupId).then((response) => {
            props.joinGroup(true);
        })
        .catch((err) => {
            console.log(err);
            props.joinGroup(false);
        })
        .finally(() => {
            setSaving(false);
            setGroupId("");
        })
    }

    return (
        <Dialog visible={props.visible} onHide={props.hide} header="Join Group">
            <div className="flex-col add-popup">
                <div>
                    <h4>Group ID</h4>
                    <InputText className="full-width" value={groupId} onChange={(e) => setGroupId(e.target.value)} />
                </div>
            </div>
            <hr />
            <div className="flex-row" style={{justifyContent: 'flex-end'}}>
                <Button
                    icon="pi pi-times"
                    label="Cancel"
                    onClick={props.hide}
                    className="p-button-outlined p-button-danger"
                    style={{margin: 10}}
                />
                <Button
                    icon="pi pi-save"
                    label="Save"
                    onClick={() => joinGroup()}
                    loading={saving}
                    className="p-button-outlined p-button-success"
                    style={{margin: 10}}
                />
            </div>
        </Dialog>
    )
}

export default JoinGroupComponent;

