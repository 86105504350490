import React from 'react';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useAxios } from '../../utils/hooks.ts';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import { sortFunction } from '../../utils/functions';

export default function CreditPaymentComponent({ account, visible, hide, displayToast}) {
    // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const [ accountList, setAccountList ] = React.useState([]);
    const [ currentTime, setCurrentTime ] = React.useState();
    const axiosInstance = useAxios();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: 0.00,
            confirmation: '',
            account: null,
            createTime: currentTime
        },
        validate: (data) => {
            let errors = {};

            if (!data.amount) {
                errors.amount = 'Amount is required.';
            }

            if (!data.account) {
                errors.account = 'Account is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setSaving(true);
            setFormData(data);
            data.accountFromId = data.account.accountId;
            data.accountToId = account.accountId;
            data.description = "Payment"
            axiosInstance.current.post("/accounts/deposit", data).then(() => {
                formik.resetForm();
                displayToast(true, "Payment created!")
            })
            .catch((err) => {
                console.error(err);
                displayToast(false, "Error while saving payment.");
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    React.useEffect(() => {
        axiosInstance.current.get("/accounts").then(response => {
            if (response.data) {
                const otherAccount = {
                    name: "Other"
                }
                const accounts = response.data.filter(a => a.accountId !== account.accountId).sort(sortFunction);
                const favorites = accounts.filter(a => a.favorite).sort(sortFunction);
                const others = accounts.filter(a => !a.favorite).sort(sortFunction);
                setAccountList([otherAccount, ...favorites, ...others]);
            }
        })
        setCurrentTime(new Date());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    return (
        <Dialog header="Make Credit Payment" visible={visible} onHide={hide}>
            <div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="field m20">
                            <span className="p-float-label">
                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} id="amount" name="amount" value={formik.values.amount} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('amount') })}/>
                                <label htmlFor="amount" className={classNames({ 'p-error': isFormFieldValid('amount') })}>Amount *</label>
                            </span>
                            {getFormErrorMessage('amount')}
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="confirmation" name="confirmation" value={formik.values.confirmation} onChange={formik.handleChange}/>
                                <label htmlFor="confirmation">Confirmation Code</label>
                            </span>
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Dropdown id="account" name="account" value={formik.values.account} onChange={formik.handleChange} options={accountList} optionLabel="name" className={classNames({ 'p-invalid': isFormFieldValid('account') })} />
                                <label htmlFor="account" className={classNames({ 'p-error': isFormFieldValid('account') })}>Account *</label>
                            </span>
                            {getFormErrorMessage('account')}
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Calendar id="createTime" name="createTime" value={formik.values.createTime} onChange={formik.handleChange} showTime hourFormat="12" />
                            </span>
                        </div>
                        <div className="flex-row justify-content-center mt20">
                            <Button style={{ margin: 10 }} type="submit" label="Make Payment" loading={saving} icon='pi pi-save'/>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}