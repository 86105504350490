import * as React from 'react';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { useAxios } from '../../utils/hooks.ts';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import ProgressWrapper from '../../components/other/ProgressWrapper';
import TransactionList from '../../components/transactions/TransactionList';
import CreateEditTransaction from '../../components/transactions/CreateEditTransaction';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { confirmPopup } from 'primereact/confirmpopup';

export default function Transactions() {
    const [ loading, setLoading ] = React.useState(false);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ transactions, setTransactions ] = React.useState([]);
    const [ createTransactionVisible, setCreateTransactionVisible ] = React.useState(false);
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const toast = React.useRef(null);
    const op = React.useRef(null);

    const displayToast = (success, msg) => {
        if (success) {
            toast.current.show({severity: 'success', summary: 'Success!', detail: msg})
        } else {
            toast.current.show({severity: 'error', summary: 'Error!', detail: msg})
        }
    }

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    const toggleTransaction = () => {
        setCreateTransactionVisible(!createTransactionVisible);
        flipFetchFlag();
    }

    const confirmExportAll = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Export all transactions to contact email?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-primary',
			accept: exportAll,
			reject
		})
	}
	
	const confirmExportMonth = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Export this month's transactions to contact email?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-primary',
			accept: exportMonth,
			reject
		})
	}

    const reject = () => {}

    const exportAll = () => {
		axiosInstance.current.get("/reports/transactions/all").then(() => {
            displayToast(true, "Export generated.");
		})
		.catch(() => {
            displayToast(false, "Error while generating export.");
		})
	}
	
	const exportMonth = () => {
		axiosInstance.current.get("/reports/transactions/month").then(() => {
            displayToast(true, "Export generated.");
		})
		.catch(() => {
            displayToast(false, "Error while generating export.");
		})
	}

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/transactions").then((response) => {
            if (response.data) {
                response.data.forEach(t => {
                    t.ISOtime = t.createTime;
                    t.createTime = format(parseISO(t.createTime), "MMMM dd, yyyy h:mma");
                    if (t.updatedTime) {
                        t.updatedTime = format(parseISO(t.updatedTime), "MMMM dd, yyyy h:mma");
                    }
                });
                setTransactions(response.data);
            }
        })
        .catch((err) => {
            displayToast(false, "Unable to get transactions.");
        })
        .finally(() => {
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag])

    return (
        <>
            <Toast ref={toast} />
            <CreateEditTransaction visible={createTransactionVisible} hide={toggleTransaction} displayToast={displayToast} />
            <nav className="mt20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">Transactions</li>
				</ol>
			</nav>
            <div className="flex-col">
                <div className="title flex-row">
                    <div className="mt10">Transactions</div>
                    <Tooltip target=".option-wrapper" mouseTrack mouseTrackLeft={10} />
                    <div className="option-wrapper p10" onClick={(e) => op.current.toggle(e)} data-pr-tooltip="Options">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </div>
                    <OverlayPanel ref={op} id="options-overlay" className="options-overlaypane">
                        <div className="flex-col start">
                            <Button label="Export All Transactions" className="p-button-text p-button-plain" onClick={confirmExportAll} />
                            <Button label="Export This Month's Transactions" className="p-button-text p-button-plain" onClick={confirmExportMonth} />
                        </div>
                    </OverlayPanel>
                </div>
            </div>
            <Button style={{ marginBottom: 10 }} className="p-button-outlined" icon="pi pi-plus" label="Create Transaction" onClick={() => {setCreateTransactionVisible(true)}} />
            {loading ?
                <ProgressWrapper />
            :
                <TransactionList transactions={transactions} flipFetchFlag={flipFetchFlag} displayToast={displayToast} viewAll={true}/>
            }
        </>
    )
}