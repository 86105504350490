import React from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { useAxios } from '../../utils/hooks.ts';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { sortFunction } from '../../utils/functions';

export default function TransferComponent({ account, visible, hide, displayToast }) {
     // eslint-disable-next-line
    const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const [ accounts, setAccounts ] = React.useState([]);
	const axiosInstance = useAxios();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: '',
            amount: '0.00',
            source: account ? accounts.find(a => a.accountId === account.accountId) : null,
            destination: null,
        },
        validate: (data) => {
            let errors = {};

            if (!data.amount) {
                errors.amount = 'Amount is required.';
            }

            if (!data.source) {
                errors.source = "Source account is required.";
            }
            
            if (!data.destination) {
                errors.destination = 'Destination account is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.accountFromId = data.source.accountId;
            data.accountToId = data.destination.accountId;
            axiosInstance.current.post("/accounts/deposit", data).then(() => {
                formik.resetForm();
                displayToast(true, "Transfer created!")
            })
            .catch((err) => {
                console.error(err);
                displayToast(false, "Error while creating transfer.");
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    React.useEffect(() => {
        axiosInstance.current.get("/accounts").then((response) => {
            if (response.data) {
                const fav = response.data.filter(a => a.favorite).sort(sortFunction);
                const non = response.data.filter(a => !a.favorite).sort(sortFunction);
                setAccounts([{}, ...fav, ...non]);
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog header={'Transfer Funds'} visible={visible} onHide={hide}>
			<div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="description" name="description" value={formik.values.description} onChange={formik.handleChange} autoFocus />
                                <label htmlFor="description" >Description</label>
                            </span>
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} id="amount" name="amount" value={formik.values.amount} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('amount') })}/>
                                <label htmlFor="amount" className={classNames({ 'p-error': isFormFieldValid('amount') })}>Amount *</label>
                                {getFormErrorMessage('amount')}
                            </span>
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Dropdown id="source" name="source" value={formik.values.source} onChange={formik.handleChange} options={accounts} optionLabel="name" className={classNames({ 'p-invalid': isFormFieldValid('source') })}/>
                                <label htmlFor="source" className={classNames({ 'p-error': isFormFieldValid('source') })}>Source Account *</label>
                            </span>
                            {getFormErrorMessage('source')}
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Dropdown id="destination" name="destination" value={formik.values.destination} onChange={formik.handleChange} options={accounts} optionLabel="name" className={classNames({ 'p-invalid': isFormFieldValid('destination') })}/>
                                <label htmlFor="destination" className={classNames({ 'p-error': isFormFieldValid('destination') })}>Destination Account *</label>
                            </span>
                            {getFormErrorMessage('destination')}
                        </div>
                        <div className="flex-row justify-content-center mt20">
                            <Button style={{ margin: 10 }} type="submit" label="Transfer Funds" loading={saving} icon='pi pi-save'/>
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
    )
}