import * as React from 'react';
import { useAxios } from '../../../../utils/hooks.ts';
import { Button } from 'primereact/button';
import AddGroupComponent from './AddGroup'
import JoinGroupComponent from './JoinGroup';
import InviteComponent from './Invite';
import GroupDetail from './GroupDetail';
import { confirmPopup } from 'primereact/confirmpopup';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

export default function GroupsComponent(props) {
	const [ groups, setGroups ] = React.useState([]);
	const [ selectedGroup, setSelectedGroup ] = React.useState();
	
	const [ fetchGroupsFlag, setFetchGroupsFlag ] = React.useState(false);
	const [ createGroupVisible, setCreateGroupVisible ] = React.useState(false);
	const [ joinGroupVisible, setJoinGroupVisible ] = React.useState(false);
	const [ inviteVisible, setInviteVisible ] = React.useState(false);
	const axiosInstance = useAxios();
	const toast = React.useRef(null);

	const toggleCreateVisible = () => {
		setCreateGroupVisible(!createGroupVisible);
	}

	const toggleJoinVisible = () => {
		setJoinGroupVisible(!joinGroupVisible);
	}

	const toggleInviteVisible = () => {
		setInviteVisible(!inviteVisible);
	}

	const saveGroup = (success) => {
		if (success) {
			toast.current.show({
				severity: 'success',
				summary: 'Saved!'
			});
			flipFetchGroupsFlag();
			
		} else {
			toast.current.show({
				severity: 'error',
				summary: 'Oops!',
				detail: 'Unable to create new group'
			});
		}
		toggleCreateVisible();
	}

	const joinGroup = (success) => {
		if (success) {
			toast.current.show({
				severity: 'success',
				summary: 'Saved!'
			});
			flipFetchGroupsFlag();
			
		} else {
			toast.current.show({
				severity: 'error',
				summary: 'Oops!',
				detail: 'Unable to join group'
			});
		}
		toggleJoinVisible();
	}

	const inviteSent = (success) => {
		if (success) {
			toast.current.show({
				severity: 'success',
				summary: 'Sent!'
			});
			flipFetchGroupsFlag();
			
		} else {
			toast.current.show({
				severity: 'error',
				summary: 'Oops!',
				detail: 'Unable to send invite'
			});
		}
		toggleInviteVisible();
	}

	const handleGroupChange = (e) => {
		const groupId = e.value.groupId;
		if (!groupId) {
			setSelectedGroup({});
			axiosInstance.current.put("/groups/deselect").then(() => {
				preferenceSaved(true);
			})
			.catch((err) => {
				console.log(err);
				preferenceSaved(false);
			})
			.finally(() => {
				// get new tags with associated group
				// flipFetchTagsFlag();
			})
		} else {
			axiosInstance.current.put("/groups/select/" + groupId).then(() => {
				preferenceSaved(true);
				setSelectedGroup(e.value);
			})
			.catch((err) => {
				console.log(err);
				preferenceSaved(false);
			})
			.finally(() => {
				// get new tags with associated group
				// flipFetchTagsFlag();
			});
		}
		
	}

	const handleGroupLeave = (id) => {
		axiosInstance.current.put("/groups/leave/" + id).then(() => {
			preferenceSaved(true);
			flipFetchGroupsFlag();
		})
		.catch(() => {
			preferenceSaved(false);
		})
	}

	const handleGroupDelete = (group,  e) => {
		confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete " + group.name + "?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => confirmDelete(group),
            reject
        })
	}

	const confirmDelete = (group) => {
		axiosInstance.current.delete("/groups/" + group.groupId).then(() => {
			preferenceSaved(true);
			flipFetchGroupsFlag();
		})
		.catch(() => {
			preferenceSaved(false);
		})
	}

	const reject = () => {}

	const preferenceSaved = (success) => {
		if (success) {
			toast.current.show({
				severity: 'success',
				summary: 'Saved!'
			});
		} else {
			toast.current.show({
				severity: 'error',
				summary: 'Oops!',
				detail: 'Unable to save preference'
			});
		}
	}

	const flipFetchGroupsFlag = () => {
		setFetchGroupsFlag(!fetchGroupsFlag);
	}

	React.useEffect(() => {
		axiosInstance.current.get("/groups").then((response) => {
			setGroups([{name: 'None'}, ...response.data]);
			setSelectedGroup(response.data.find(g => g.selected));
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchGroupsFlag])

	return (
		<React.Fragment>
			<Toast ref={toast} />
			<AddGroupComponent visible={createGroupVisible} hide={toggleCreateVisible} saveGroup={saveGroup} />
			<JoinGroupComponent visible={joinGroupVisible} hide={toggleJoinVisible} joinGroup={joinGroup} />
			<InviteComponent visible={inviteVisible} hide={toggleInviteVisible} inviteSent={inviteSent} group={selectedGroup}/>
			<div className="title">
				Groups
			</div>
			<div className="flex-row">
				<Button
					label="Create Group"
					className="p-button-outlined"
					style={{ margin: 10 }}
					onClick={toggleCreateVisible}
				/>
				<Button
					label="Join Group"
					className="p-button-outlined"
					style={{ margin: 10 }}
					onClick={toggleJoinVisible}
				/>
			</div>
			<div>
				{groups.filter(g => g.groupId).map(g => (
					<GroupDetail key={g.groupId} group={g} leaveGroup={handleGroupLeave} deleteGroup={handleGroupDelete}/>
				))}
				{groups.length > 1 ?
					<div className="flex-row between plain-text pt10">
						<div>Active Group:</div>
						<Dropdown options={groups} value={selectedGroup} optionLabel={"name"} onChange={handleGroupChange} />
					</div>
				:
					<></>
				}
				{selectedGroup ?
					<div className="flex-row between plain-text pt10">
						<div>
						</div>
						<Button className="p-button-outlined" label="Send Invite" onClick={toggleInviteVisible}/>
					</div>
				:
					<></>
				}
			</div>
		</React.Fragment>
	)
}
