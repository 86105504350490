import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';
import { sortFunction } from '../../utils/functions';

export default function CreateEditCategory({ category, visible, hide, displayToast }) {
     // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const [ payees, setPayees ] = React.useState([]);
	const axiosInstance = useAxios();

    const dayList = [
        1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
    ]

	const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: category ? category.name : '',
            description: category ? category.description : '',
            allowed: category ? category.allowed : '0.00',
            bill : category ? category.frequency != null : '',
            once: category ? category.frequency === 'ONCE' : '',
            day: category ? category.day : '',
            payee: category ? payees.find(p => p.payeeId === category.payeeId) : {}
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Name is required.';
            }

            if (!data.allowed) {
                errors.allowed = 'Allowed amount is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setSaving(true);
            setFormData(data);
            data.categoryId = category?.categoryId;
            data.payeeId = data.payee?.payeeId;
            data.frequency = data.bill ? (data.once ? 'ONCE' : 'MONTH') : null;
            axiosInstance.current.post("/categories", data).then(() => {
                formik.resetForm();
                displayToast(true, "Category saved!");
            })
            .catch((err) => {
                console.error(err);
                displayToast(false, "Error while saving category.");
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    React.useEffect(() => {
        axiosInstance.current.get("/payees").then((response) => {
            if (response.data) {
                const fav = response.data.filter(p => p.favorite).sort(sortFunction);
                const non = response.data.filter(p => !p.favorite).sort(sortFunction);
                setPayees([{},...fav, ...non]);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog header={category ? 'View/Edit Category' : 'Create Category / Bill'} visible={visible} onHide={hide}>
			<div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus autoComplete="off" className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Name *</label>
                            </span>
                            {getFormErrorMessage('name')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputText id="description" name="description" value={formik.values.description} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('description') })}/>
                                <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>Description</label>
                            </span>
                            {getFormErrorMessage('description')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} id="allowed" name="allowed" value={formik.values.allowed} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('allowed') })}/>
                                <label htmlFor="allowed" className={classNames({ 'p-error': isFormFieldValid('allowed') })}>Allowed *</label>
                            </span>
                            {getFormErrorMessage('allowed')}
                        </div>
                        <div className="field-checkbox m20">
                            <Checkbox inputId="bill" name="bill" checked={formik.values.bill} onChange={formik.handleChange} />
                            <label style={{ marginLeft: 10 }} htmlFor="bill">Bill?</label>
                        </div>
                        {formik.values.bill ?
                        <>
                            <div className="field-checkbox m20">
                                <Checkbox inputId="once" name="once" checked={formik.values.once} onChange={formik.handleChange} />
                                <label style={{ marginLeft: 10 }} htmlFor="once">One time?</label>
                            </div>
                            {!formik.values.once ?
                            <div className="field m20">
                                <span className="p-float-label">
                                    <Dropdown id="day" name="day" value={formik.values.day} onChange={formik.handleChange} options={dayList} />
                                    <label htmlFor="day">Due Date</label>
                                </span>
                            </div>
                            :
                            <></>
                            }
                            <div className="field m20">
                                <span className="p-float-label">
                                    <Dropdown id="payee" name="payee" value={formik.values.payee} onChange={formik.handleChange} options={payees} optionLabel="name" />
                                    <label htmlFor="payee">Payee</label>
                                </span>
                            </div>
                        </>
                        :
                        <></>
                        }

                        <div className="flex-row justify-content-center mt20">
                            {category?
                            <Button style={{ margin: 10 }} type="submit" label="Save Category" loading={saving} icon='pi pi-save'/>
                            :
                            <Button style={{ margin: 10 }} type="submit" label="Create Category" loading={saving} icon='pi pi-plus'/>
                            }
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
    )
}