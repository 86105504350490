import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { Dropdown } from 'primereact/dropdown';
import { confirmPopup } from 'primereact/confirmpopup';
import { useAxios } from '../../utils/hooks.ts';
import { getMonth, getYear, parseISO } from 'date-fns';
import CreateEditTransaction from "./CreateEditTransaction";
import TagDisplay from "../tags/TagDisplay";
import { FilterMatchMode } from 'primereact/api';

export default function TransactionList({ transactions, flipFetchFlag, displayToast, viewAll }) {
    const [ createEditVisible, setCreateEditVisible ] = React.useState(false);
    const [ selected, setSelected ] = React.useState();
    const [ tags, setTags ] = React.useState([]);
    const [ selectedTag, setSelectedTag ] = React.useState({});
    const [ filteredTransactions, setFilteredTransactions ] = React.useState([]);
    // eslint-disable-next-line
    const [ transactionFilters, setTransactionFilters ] = React.useState({
        'description': { value: null, matchMode: FilterMatchMode.CONTAINS},
        'amount': { value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'accountName': { value: null, matchMode: FilterMatchMode.CONTAINS},
        'categoryName': { value: null, matchMode: FilterMatchMode.CONTAINS}
    })
    const axiosInstance = useAxios();

    const items = [
        {
            label: "This Month",
            command: () => {
                const tx = transactions.filter(t => {
                    const transactionMonth = getMonth(parseISO(t.ISOtime));
                    const transactionYear = getYear(parseISO(t.ISOtime));
                    const thisMonth = getMonth(new Date());
                    const thisYear = getYear(new Date());
                    return transactionMonth === thisMonth && transactionYear === thisYear;
                });
                if (selectedTag.tagId !== '') {
                    setFilteredTransactions(tx.filter(t => {
                        const x = t.tags.find(tag => tag.tagId === selectedTag.tagId);
                        return x !== undefined
                    }));
                } else {
                    setFilteredTransactions(tx);
                }
            }
        },
        {
            label: "Last Month",
            command: () => {
                const tx = transactions.filter(t => {
                    const transactionMonth = getMonth(parseISO(t.ISOtime));
                    const transactionYear = getYear(parseISO(t.ISOtime));
                    const lastMonth = getMonth(new Date()) - 1;
                    const thisYear = getYear(new Date());
                    return transactionMonth === lastMonth && transactionYear === thisYear;
                });
                if (selectedTag.tagId !== '') {
                    setFilteredTransactions(tx.filter(t => {
                        const x = t.tags.find(tag => tag.tagId === selectedTag.tagId);
                        return x !== undefined
                    }));
                } else {
                    setFilteredTransactions(tx);
                }
            }
        },
        {
            label: "Year to Date",
            command: () => {
                const tx = transactions.filter(t => {
                    const transactionYear = getYear(parseISO(t.ISOtime));
                    const thisYear = getYear(new Date());
                    return transactionYear === thisYear;
                });
                if (selectedTag.tagId !== '') {
                    setFilteredTransactions(tx.filter(t => {
                        const x = t.tags.find(tag => tag.tagId === selectedTag.tagId);
                        if (x) {

                        }
                        return x !== undefined
                    }));
                } else {
                    setFilteredTransactions(tx);
                }
            }
        }
    ]

    const viewAllTransactions = () => {
        if (selectedTag.tagId !== '') {
            setFilteredTransactions(transactions.filter(t => {
                const x = t.tags.find(tag => tag.tagId === selectedTag.tagId);
                return x !== undefined
            }));
        } else {
            setFilteredTransactions(transactions);
        }
    }


    const toggle = () => {
        setCreateEditVisible(false);
        flipFetchFlag();
    }

    const reject = () => {
    };

    const confirmDeleteTransaction = (transaction, e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this transaction?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deleteTransaction(transaction.transactionId),
            reject
        })
    }

    const deleteTransaction = (transactionId) => {
        axiosInstance.current.delete("/transactions/" + transactionId)
            .then(() => {
                displayToast(true, "Transaction deleted!");
            })
            .catch(() => {
                displayToast(false, "Transaction not deleted. Sad face. :{");
            })
            .finally(() => {
                flipFetchFlag();
            })
    }

    const tagBody = (rowData) => {
        return <TagDisplay tags={rowData.tags} removable={false} />
    }

    const viewEditBody = (rowData) => {
        return (
            <Button className="p-button-text" label="View/Edit" icon="pi pi-pencil" onClick={() => {
                setSelected(rowData);
                setCreateEditVisible(true);
            }} />
        )
    }

    const deleteBody = (rowData) => {
        return (
            <Button className="p-button-text p-button-danger" label="Delete" icon="pi pi-trash" onClick={(e) => confirmDeleteTransaction(rowData, e)} />
        )
    }

    const selectTag = (e) => {
        setSelectedTag(e.value);
        if (e.value.tagId === '') {
            setFilteredTransactions(transactions);
        } else {
            setFilteredTransactions(transactions.filter(t => {
                const x = t.tags.find(tag => tag.tagId === e.value.tagId);
                return x !== undefined
            }));
        }
    }

    React.useEffect(() => {
        axiosInstance.current.get("/transactions/tags").then(response => {
            const nullTag = {tagId: '', text: 'None'};
            setSelectedTag(nullTag);
            setTags([nullTag, ...response.data]);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    React.useEffect(() => {
        transactions.forEach(t => t.amount = (t.amount * 1).toFixed(2));
        setFilteredTransactions(transactions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactions])

    return (
        <>
            <CreateEditTransaction transaction={selected} visible={createEditVisible} hide={toggle} displayToast={displayToast} />
            <div className="mt20">
                <div className="flex-col align-center">
                    {viewAll && <SplitButton
                        label="All Transactions"
                        model={items}
                        onClick={viewAllTransactions}
                        className="p-button-text p-button-plain mr-2 mb-2"
                        style={{ width: 200 }}>
                    </SplitButton>}
                    <div className="secondary-title flex-row center">
                        <div style={{ marginRight: 10 }}>Filter by tag:</div>
                        <div>
                            <Dropdown value={selectedTag} options={tags} onChange={selectTag} optionLabel="text" placeholder="Select tag to filter" />
                        </div>
                    </div>
                </div>
                <div class="secondary-title flex-row end full-width">
                    Total: ${filteredTransactions && (filteredTransactions.map(t => t.amount).reduce((accum, curr) => accum + curr * 1, 0)).toFixed(2)}
                </div>
                <DataTable
                    value={filteredTransactions}
                    rows={10}
                    paginator
                    responsiveLayout="stack"
                    size="small"
                    dataKey="transactionId"
                    emptyMessage="No transactions available"
                    filters={transactionFilters}
                    filterDisplay="row"
                >
                    <Column field="description" header="Description" sortable filter filterPlaceholder="Description"></Column>
                    <Column field="amount" header="Amount" sortable filter filterPlaceholder="Amount"></Column>
                    <Column field="accountName" header="Account" sortable filter filterPlaceholder="Account"></Column>
                    <Column field="categoryName" header="Category" sortable filter filterPlaceholder="Category"></Column>
                    <Column field="createTime" header="Created at" sortable></Column>
                    <Column body={tagBody}></Column>
                    <Column body={viewEditBody}></Column>
                    <Column body={deleteBody}></Column>
                </DataTable>

                
            </div>
        </>
    )
}