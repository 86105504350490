import * as React from 'react';
import { useAxios } from '../../utils/hooks.ts';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { format, getMonth, getYear, parseISO } from 'date-fns';
import ProgressWrapper from '../other/ProgressWrapper.js';
import { useNavigate } from 'react-router-dom';
import CreateEditTransaction from '../transactions/CreateEditTransaction';

const monthList = [
    {label: "Jan", value: 1},
    {label: "Feb", value: 2},
    {label: "Mar", value: 3},
    {label: "Apr", value: 4},
    {label: "May", value: 5},
    {label: "Jun", value: 6},
    {label: "Jul", value: 7},
    {label: "Aug", value: 8},
    {label: "Sep", value: 9},
    {label: "Oct", value: 10},
    {label: "Nov", value: 11},
    {label: "Dec", value: 12},

]

export default function IncomeReport() {
    const [ loading, setLoading ] = React.useState(false);
    const [ report, setReport ] = React.useState(null);
    const [ month, setMonth ] = React.useState(getMonth(new Date()) + 1);
    const [ year, setYear ] = React.useState(getYear(new Date()));
    const [ incomeTotal, setIncomeTotal ] = React.useState(0);
    const [ expenseTotal, setExpenseTotal] = React.useState(0);
    const [ netIncome, setNetIncome ] = React.useState(0);
    const [ createTransactionVisible, setCreateTransactionVisible ] = React.useState(false);
    const [ selectedTransaction, setSelectedTransaction ] = React.useState();
    const axiosInstance = useAxios();
    const navigate = useNavigate();

    const toggleTransaction = () => {
        setSelectedTransaction(null);
        setCreateTransactionVisible(!createTransactionVisible);
    }

    const selectTransaction = (e) => {
        setSelectedTransaction(e.transaction);
        setCreateTransactionVisible(true);
    }

    const displayToast = () => {

    }

    const getReport = () => {
        setLoading(true);
        axiosInstance.current.get("/reports/income", { params: { month: month, year: year}}).then((response) => {
            response.data.expenses.forEach(e => {
                const t = e.transaction;
                t.ISOtime = t.createTime;
                t.createTime = format(parseISO(t.createTime), "MMMM dd, yyyy h:mma");
            })
            setReport(response.data);
            const incomeTotal = response.data.incomes.map(i => i.amount).reduce((accum, curr) => accum + curr, 0);
            setIncomeTotal(incomeTotal);
            const expenseTotal = response.data.expenses.map(e => e.amount).reduce((accum, curr) => accum + curr, 0);
            setExpenseTotal(expenseTotal);
            setNetIncome(incomeTotal - expenseTotal);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            <div style={{ alignItems: 'baseline' }} className="flex-row">
                <div className="secondary-title m10">Month:</div>
                <Dropdown value={month} options={monthList} onChange={(e) => setMonth(e.value)} />
                <div className="secondary-title m10">Year:</div>
                <InputNumber value={year} onValueChange={(e) => setYear(e.value)} useGrouping={false} size={1} min={2000} max={3000} />
                <Button style={{ margin: 10 }} label="Get Report" onClick={getReport} loading={loading} />
            </div>
            {loading ?
                <>
                    <ProgressWrapper />
                </>
                :
                <>
                    <CreateEditTransaction transaction={selectedTransaction} visible={createTransactionVisible} hide={toggleTransaction} displayToast={displayToast} />
                    {report ?
                    <div className="grid-container">
                        <div className="secondary-title" style={{ gridColumn: "1 / span 4"}}>Income</div>
                        {report && report.incomes.map(i => (
                            <>
                            <div style={{ gridColumn: 2, textAlign: 'right', paddingRight: 10 }}><Button className="p-button-text" label={i.accountName} onClick={() => navigate("/accounts/" + i.accountId)} /></div>
                            <div style={{ gridColumn: 3, textAlign: 'right', paddingTop: '0.5rem'}}>${i.amount.toFixed(2)}</div>
                            </>
                        ))}
                        <div className="secondary-title mt20" style={{ gridColumn: 4, textAlign: 'right'}}>Income Total: ${incomeTotal.toFixed(2)}</div>
                        <div className="secondary-title" style={{ gridColumn: "1 / span 4"}}>Expenses</div>
                        {report && report.expenses.filter(e => e.categoryId !== null).map(e => (
                            <>
                            <div style={{ gridColumn: 1, textAlign: 'right', paddingRight: 10}}>{e.categoryId && <Button className="p-button-text" label={e.categoryName} onClick={() => navigate("/categories/" + e.categoryId)} />}</div>
                            <div style={{ gridColumn: 2, textAlign: 'right', paddingRight: 10, paddingTop: '0.5rem'}}><Button className="p-button-text" label={e.description} onClick={() => selectTransaction(e)} /></div>
                            <div style={{ gridColumn: 3, textAlign: 'right', paddingTop: '0.5rem'}}>${e.amount.toFixed(2)}</div>
                            </>
                        ))}
                        {report && report.expenses.filter(e => e.categoryId === null).map(e => (
                            <>
                            <div style={{ gridColumn: 1, textAlign: 'right', paddingRight: 10}}>{e.categoryId && <Button className="p-button-text" label={e.categoryName} onClick={() => navigate("/categories/" + e.categoryId)} />}</div>
                            <div style={{ gridColumn: 2, textAlign: 'right', paddingRight: 10, paddingTop: '0.5rem'}}><Button className="p-button-text" label={e.description} onClick={() => selectTransaction(e)} /></div>
                            <div style={{ gridColumn: 3, textAlign: 'right', paddingTop: '0.5rem'}}>${e.amount.toFixed(2)}</div>
                            </>
                        ))}
                        <div className="secondary-title mt20" style={{ gridColumn: 4, textAlign: 'right'}}>Expense Total: ${expenseTotal.toFixed(2)}</div>
                        <div className={"secondary-title mt20 " + (netIncome < 0 ? 'negative-balance' : '')} style={{ gridColumn: 4, textAlign: 'right'}}>Net Income: ${netIncome.toFixed(2)}</div>
                    </div>
                    :
                    <></>
                    }
                </>
            }
        </>
    )
}