import * as React from 'react';
import { InputText } from 'primereact/inputtext';
import { confirmPopup } from 'primereact/confirmpopup';
import { useAxios } from '../../../../utils/hooks.ts';
import { Button } from 'primereact/button';
import { useKeycloak } from '@react-keycloak/web';

export default function UserSettings({ preferenceSaved, contactEmail, setContactEmail }) {
    const { keycloak } = useKeycloak();
    const axiosInstance = useAxios();

    const deleteAccount = () => {
        const pref = {
            key: "delete_user",
            value: true
        }
        axiosInstance.current.put("/preferences", pref).then(() => {
            keycloak.logout();
        });
    }

    const reject = () => {
    };

    const confirmDelete = (e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "WARNING! Are you sure you want to delete this account? THIS IS UNRECOVERABLE!",
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            accept: deleteAccount,
            reject
        })
    }

    const handleEmailBlur = (e) => {
		const newPref = {
			key: 'contact_email',
			value: e.target.value
		};
		axiosInstance.current.put("/preferences", newPref)
			.then(() => {
				preferenceSaved(true);
			})
			.catch(() => {
				preferenceSaved(false);
			})
	}

    return (
        <React.Fragment>
			<div className="title">
				User Settings
			</div>
			<div>
				<div className="flex-row between plain-text pt10">
					<div>Contact email: </div>
					<InputText value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} onBlur={handleEmailBlur} />
				</div>
				<div className="flex-row between plain-text pt10">
                    <div></div>
                    <Button className="p-button-text p-button-danger" label="Delete Account" onClick={confirmDelete} />
                </div>
			</div>
		</React.Fragment>
    )

}