import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmPopup } from "primereact/confirmpopup";
import { useNavigate } from "react-router-dom";
import { useAxios } from '../../utils/hooks.ts';
import CreateEditPayee from "./CreateEditPayee";

export default function PayeeList({ payees, flipFetchFlag, displayToast }) {
    const [ createEditVisible, setCreateEditVisible ] = React.useState(false);
    const [ selected, setSelected ] = React.useState();
    const navigate = useNavigate();
    const axiosInstance = useAxios();

    const selectRow = (e) => {
        navigate("/payees/" + e.value.payeeId);
    }

    const toggle = () => {
        setCreateEditVisible(false);
        flipFetchFlag();
    }

    const viewEditBody = (rowData) => {
        return (
            <Button className="p-button-text" label="View/Edit" icon="pi pi-pencil" onClick={() => {
                setSelected(rowData);
                setCreateEditVisible(true);
            }} />
        )
    }

    const deleteBody = (rowData) => {
        return <Button className="p-button-text p-button-danger" label="Delete" icon="pi pi-trash" onClick={(e) => confirmDeletePayee(rowData, e)} />
    }

    const deletePayee = (payee) => {
        axiosInstance.current.delete("/payees/" + payee.payeeId)
            .then(() => {
                flipFetchFlag();
            })
            .catch(() => {
                displayToast(false, "Unable to delete payee.");
            })
    };

    const reject = () => {
    };

    const confirmDeletePayee = (payee, e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this payee?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deletePayee(payee),
            reject
        })
    }

    return (
        <>
            <CreateEditPayee payee={selected} visible={createEditVisible} hide={toggle} displayToast={displayToast} />
            <div className="mt20">
                <Button style={{ marginBottom: 10 }} className="p-button-outlined" icon="pi pi-plus" label="Create Payee" onClick={() => {setCreateEditVisible(true)}} />
                <DataTable
                    value={payees}
                    rows={10}
                    paginator
                    responsiveLayout="stack"
                    size="small"
                    selectionMode="single"
                    onSelectionChange={(e) => selectRow(e)}
                    dataKey="payeeId"
                >
                    <Column field="name" header="Name" sortable></Column>
                    <Column field="description" header="Description" sortable></Column>
                    <Column body={viewEditBody}></Column>
                    <Column body={deleteBody}></Column>
                </DataTable>
            </div>
        </>
    )
}