import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { useAxios } from '../../utils/hooks.ts';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import CategoryList from '../../components/categories/CategoryList';
import ProgressWrapper from '../../components/other/ProgressWrapper';
import CategoryOverview from '../../components/categories/CategoryOverview';
import { sortFunction } from '../../utils/functions';

export default function Categories() {
    const [ loading, setLoading ] = React.useState(false);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ data, setData ] = React.useState([]);
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const toast = React.useRef(null);

    const displayToast = (success, msg) => {
        if (success) {
            toast.current.show({severity: 'success', summary: 'Success!', detail: msg})
        } else {
            toast.current.show({severity: 'error', summary: 'Error!', detail: msg})
        }
    }

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/categories").then((response) => {
            if (response.data) {
                const fav = response.data.filter(c => c.favorite).sort(sortFunction);
                const non = response.data.filter(c => !c.favorite).sort(sortFunction);
                setData([...fav, ...non]);
            }
        })
        .catch((err) => {
            displayToast(false, "Unable to retrieve categories.");
        })
        .finally(() => {
            setLoading(false);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag]);

    return (
        <>
            <Toast ref={toast} />
            <nav className="mt20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">Categories</li>
				</ol>
			</nav>
            <div className="title mt20">Categories / Bills</div>
            <CategoryOverview categories={data.filter(c => c.frequency !== 'ONCE')} />
            {loading ?
                <ProgressWrapper />
            :
                <CategoryList categories={data} flipFetchFlag={flipFetchFlag} displayToast={displayToast} />
            }
        </>
    )
}