import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { Sidebar } from 'primereact/sidebar';
import Titlebar from './Titlebar';
import Menu from './Menu';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../../pages/home/HomePage';
import Categories from '../../pages/categories/Categories';
import Accounts from '../../pages/accounts/Accounts';
import CategoryDetail from '../../pages/categories/CategoryDetail';
import AccountDetail from '../../pages/accounts/AccountDetail';
import Payees from '../../pages/payees/Payees';
import Footer from './Footer';
import PayeeDetail from '../../pages/payees/PayeeDetail';
import About from '../../pages/about/About';
import Transactions from '../../pages/transactions/Transactions';
import ProfilePage from '../../pages/profile/Profile';
import Reports from '../../pages/reports/Reports';

export default function Main() {
    const [ menuVisible, setMenuVisible ] = React.useState(false);
    const { initialized } = useKeycloak();

    return (
        <>
            {!initialized ?
                <div className="flex-col align-center" style={{ marginTop: 300 }}>
                    <a href="/" className="title" style={{ textDecoration: 'none'}}>
                        Budgetish
                    </a>
                    <div className="secondary-title">
                        by New Labs
                    </div>
                </div>
            :
                <div>
                    <Sidebar visible={menuVisible} onHide={() => setMenuVisible(false)}>
                        <Menu setMenuVisible={setMenuVisible} />
                    </Sidebar>
                    <Titlebar setMenuVisible={setMenuVisible} />
                    <div style={{ paddingBottom: 100, zIndex: 100 }}>
                        <Routes>
                            <Route exact path="/" element={<HomePage />} />
                            <Route path="/categories" element={<Categories />} />
                            <Route path="/categories/:id" element={<CategoryDetail />} />
                            <Route path="/accounts" element={<Accounts />} />
                            <Route path="/accounts/:id" element={<AccountDetail />} />
                            <Route path="/payees" element={<Payees />} />
                            <Route path="/payees/:id" element={<PayeeDetail />} />
                            <Route path="/transactions" element={<Transactions />} />
                            <Route path="/reports" element={<Reports />} />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/about" element={<About />} />
                        </Routes>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}