import * as React from 'react';
import { Card } from 'primereact/card';

export default function CategoryOverview({ categories }) {
    const [ firstHalf,  setFirstHalf ] = React.useState([]);
    const [ firstTotal, setFirstTotal ] = React.useState(0);
    const [ firstHalfDue, setFirstHalfDue ] = React.useState(0);
	const [ secondHalf, setSecondHalf ] = React.useState([]);
    const [ secondTotal, setSecondTotal ] = React.useState(0);
    const [ secondHalfDue, setSecondHalfDue ] = React.useState(0);

	React.useEffect(() => {
		let accum = 0;
		let dueAccum = 0;
		const firstHalf = categories.filter(c => c.frequency && c.day < 16);
		firstHalf.forEach(b => {
			accum += b.allowed;
			if (!b.paidThisMonth) {
				dueAccum += b.allowed;
			}
		});
		setFirstTotal(accum);
		setFirstHalfDue(dueAccum);
		accum = 0;
		dueAccum = 0;
		const secondHalf = categories.filter(c => c.frequency && c.day >= 16);
		secondHalf.forEach(b => {
			accum += b.allowed
			if (!b.paidThisMonth) {
				dueAccum += b.allowed;
			}
		});
        if (firstHalf.length > secondHalf.length) {
            const bufferCount = firstHalf.length - secondHalf.length;
            for (let i = 0; i < bufferCount; i++) {
                secondHalf.push({ categoryId: i, name: '-' });
            }
        } else if (secondHalf.length > firstHalf.length) {
            const bufferCount = secondHalf.length - firstHalf.length;
            for (let i = 0; i < bufferCount; i++) {
                firstHalf.push({ categoryId: i, name: '-' });
            }
        }
        setFirstHalf(firstHalf);
        setSecondHalf(secondHalf);
		setSecondTotal(accum);
		setSecondHalfDue(dueAccum);
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categories])

	return (
		<div>
            <div className="flex-row between full-width">
                <div className="flex-col pr30 half-width">
                    <Card title="First Half of Month">
                    {firstHalf.length > 0 ?
                    firstHalf.map(b => (
                        <div className={`flex-row between plain-text ${b.paidThisMonth ? 'lighter' : ''}`}>
                            <div>{b.name}</div>
                            <div>{b.allowed?.toFixed(2)}</div>
                        </div>
                    ))
                    :
                    <div className="lighter">None</div>
                    }
                    <hr />
                    <div style={{ marginTop: 10}} className="flex-row between plain-text">
                        <div>Subtotal:</div>
                        <div className="plain-text">{firstTotal.toFixed(2)}</div>
                    </div>
                    <div style={{ marginTop: 10}} className="flex-row between plain-text">
                        <div>Remaining:</div>
                        <div className="plain-text">{firstHalfDue.toFixed(2)}</div>
                    </div>
                    </Card>
                </div>
                <div className="flex-col half-width">
                    <Card title="Second Half of Month">
                    {secondHalf.length > 0 ? 
                        <>
                        {secondHalf.map(b => (
                            <div key={b.categoryId} className={`flex-row between plain-text ${b.paidThisMonth ? 'lighter' : ''}`}>
                                <div>{b.name ? b.name : ' '}</div>
                                <div>{b.allowed?.toFixed(2)}</div>
                            </div>
                        ))}
                        </>
                    :
                    <div className="lighter">None</div>
                    }
                    <hr />
                    <div style={{ marginTop: 10}} className="flex-row between plain-text">
                        <div>Subtotal:</div>
                        <div className="plain-text">{secondTotal.toFixed(2)}</div>
                    </div>
                    <div style={{ marginTop: 10}} className="flex-row between plain-text">
                        <div>Remaining:</div>
                        <div className="plain-text">{secondHalfDue.toFixed(2)}</div>
                    </div>
                    </Card>
                </div>
            </div>
		</div>
	)
}