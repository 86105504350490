import * as React from 'react';
import { Button } from 'primereact/button';
import { useKeycloak } from "@react-keycloak/web";
import { Image } from 'primereact/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import CreateEditTransaction from '../transactions/CreateEditTransaction';

function Enviro() {
    return (
        <span style={{ color: 'red' }}>{window.HOST_ENV}</span>
    )
}

export default function Titlebar({ setMenuVisible }) {
    const [ createTransactionVisible, setCreateTransactionVisible ] = React.useState(false);
    const { keycloak } = useKeycloak();
    const displayEnv = window.HOST_ENV !== 'production';
    const navigate = useNavigate();

    const toggleTransaction = () => {
        setCreateTransactionVisible(!createTransactionVisible);
    }

    return (
        <>
        <CreateEditTransaction visible={createTransactionVisible} hide={toggleTransaction} />
        <div className="flex-row between align-center p10" style={{ backgroundColor: '#fff' }}>
            <div className="flex-row center align-center">
                <Image src="logo.png" alt='Budgetish' width='75' />
                <FontAwesomeIcon icon={faBars} style={{ fontSize: 42, margin: 10, cursor: 'pointer' }} onClick={() => setMenuVisible(true)}/>
                <Button className="p-button-outlined" label="Create Transaction" onClick={toggleTransaction}/>
                {displayEnv ? <Enviro /> : <></>}
            </div>
            <div>
                <span className="secondary-title hide-on-mobile pointer" onClick={() => navigate("/profile")}>Signed in as: {keycloak.tokenParsed?.email}</span>
                <Button style={{ marginLeft: 10 }} className="p-button-warning" label="Logout" icon='pi pi-sign-out' onClick={() => keycloak.logout()} />
            </div>
        </div>
        </>
    )

}