import * as React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import { useAxios } from '../../utils/hooks.ts';

export default function DepositListComponent({ deposits, flipFetchFlag, displayToast }) {
    const axiosInstance = useAxios();

    const amountBody = (rowData) => {
        return <div>{rowData.amount.toFixed(2)}</div>
    }

    const deleteBody = (rowData) => {
        return <Button className="p-button-text p-button-danger" label="Delete" icon="pi pi-trash" onClick={(e) => confirmDelete(rowData, e)} />
    }

    const confirmDelete = (deposit, e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this deposit/transfer?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deleteDeposit(deposit),
            reject
        })
    }

    const deleteDeposit = (deposit) => {
        axiosInstance.current.delete("/accounts/deposit/" + deposit.depositId).then(() => {
            displayToast(true, "Deposit/transfer deleted.");
        })
        .catch(() => {
            displayToast(false, "Unable to delete deposit/transfer.");
        })
        .finally(() => {
            flipFetchFlag();
        })
    }

    const reject = () => {}

    return (
        <>
            <DataTable
                className="full-width"
                paginator
                rows={10}
                value={deposits}
                responsiveLayout="stack"
                size="small"
                dataKey="depositId"
                >
                <Column body={amountBody} header="Amount ($)" sortable></Column>
                <Column field="description" header="Description"></Column>
                <Column field="accountFromName" header="Source" sortable></Column>
                <Column field="accountToName" header="Destination" sortable></Column>
                <Column field="createTime" header="Created at" sortable></Column>
                <Column body={deleteBody}></Column>
            </DataTable>
        </>
    )
}