import * as React from 'react';

export default function AccountsOverview({ accounts }) {
    let availableCash = 0.00;
    let availableCredit = 0.00;
    accounts.filter(a => !a.creditAccount).forEach(a => availableCash += a.currentAmount);
    accounts.filter(a => a.creditAccount).forEach(a => availableCredit += a.currentAmount);

    return (
        <div>
            <div className="secondary-title">
                Overview
            </div>
            <div className="flex-row full-width">
                <div className="flex-col pr30">
                    <div className="tertiary-title">
                        Available Cash
                    </div>
                    <div>
                        ${availableCash.toFixed(2)}
                    </div>
                </div>
                <div className="flex-col pr30">
                    <div className="tertiary-title">
                        Available Credit
                    </div>
                    <div>
                        ${availableCredit.toFixed(2)}
                    </div>
                </div>
            </div>
        </div>
    )
}