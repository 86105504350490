import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, parseISO, getDate, getMonth, getDaysInMonth, getYear, subDays } from 'date-fns';
import { faHome, faEllipsisH, faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as solidStar } from '@fortawesome/pro-solid-svg-icons';
import { useAxios } from '../../utils/hooks.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { confirmPopup } from 'primereact/confirmpopup';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import ProgressWrapper from '../../components/other/ProgressWrapper';
import TransactionList from '../../components/transactions/TransactionList';
import CreateEditAccount from '../../components/accounts/CreateEditAccount';
import CreateEditDeposit from '../../components/accounts/CreateEditDeposit';
import DepositListComponent from '../../components/accounts/DepositList';
import TransferComponent from '../../components/accounts/Transfer';
import CreateEditTransaction from '../../components/transactions/CreateEditTransaction';
import CreditPaymentComponent from '../../components/accounts/CreditPayment';

export default function AccountDetail() {
    const { id } = useParams();
    const [ loading, setLoading ] = React.useState(true);
    const [ loadingTransactions, setLoadingTransactions ] = React.useState(true);
    const [ loadingDeposits, setLoadingDeposits ] = React.useState(true);
    const [ loadingChart, setLoadingChart ] = React.useState(false);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ account, setAccount ] = React.useState([]);
    const [ deposits, setDeposits ] = React.useState([]);
    const [ transactions, setTransactions ] = React.useState([]);
    const [ editVisible, setEditVisible ] = React.useState(false);
    const [ createTransactionVisible, setCreateTransactionVisible ] = React.useState(false);
    const [ paymentVisible, setPaymentVisible ] = React.useState(false);
    const [ depositVisible, setDepositVisible ] = React.useState(false);
    const [ transferVisible, setTransferVisible ] = React.useState(false);
    const [ chartInterval, setChartInterval ] = React.useState(30);
    const [ chartData, setChartData ] = React.useState([]);
    const [ allTransactions, setAllTransactions ] = React.useState(false);
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const toast = React.useRef(null);
    const op = React.useRef(null);

    const intervalItems = [5, 10, 15, 30, 45, 60, 90];

    const displayToast = (success, msg) => {
        if (success) {
            toast.current.show({severity: 'success', summary: 'Success!', detail: msg})
        } else {
            toast.current.show({severity: 'error', summary: 'Error!', detail: msg})
        }
        flipFetchFlag();
    }

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    const toggleEdit = () => {
        setEditVisible(!editVisible);
    }

    const togglePayment = () => {
        setPaymentVisible(!paymentVisible);
    }

    const toggleDeposit = () => {
        setDepositVisible(!depositVisible);
        flipFetchFlag();
    }

    const toggleTransfer = () => {
        setTransferVisible(!transferVisible);
        flipFetchFlag();
    }

    const toggleFavorite = () => {
        axiosInstance.current.put("/accounts/" + account.accountId).finally(() => {
			flipFetchFlag();
		});
    }

    const toggleTransaction = () => {
        setCreateTransactionVisible(!createTransactionVisible);
        flipFetchFlag();
    }

    const accept = () => {
        axiosInstance.current.delete("/accounts/" + id)
            .then(() => {
                navigate("/accounts");
            })
            .catch(() => {
                displayToast(false, "Unable to delete account.");
            })
    };

    const reject = () => {
    };

    const confirm = (e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this account?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        })
    }

    const confirmExportAll = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Export all transactions to contact email?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-primary',
			accept: exportAll,
			reject
		})
	}
	
	const confirmExportMonth = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Export this month's transactions to contact email?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-primary',
			accept: exportMonth,
			reject
		})
	}

    const exportAll = () => {
		axiosInstance.current.get("/reports/account/" + account.accountId+ "/all").then(() => {
            displayToast(true, "Export generated.");
		})
		.catch(() => {
            displayToast(false, "Export failed.");
		})
	}
	
	const exportMonth = () => {
		axiosInstance.current.get("/reports/account/" + account.accountId+ "/month").then(() => {
            displayToast(true, "Export generated.");
		})
		.catch(() => {
            displayToast(false, "Export failed.");
		})
	}

    React.useEffect(() => {
        setLoadingChart(true);
        if (!account) {return;}
        let transactionMap = new Map();
        let depositMap = new Map();
        const historyDTO = {
            accountId: id,
            fromDate: subDays(new Date(), chartInterval)
        }
        axiosInstance.current.post("/analytics/account/history", historyDTO).then(response => {
            response.data.transactions.forEach(t => {
                const isoTime = parseISO(t.createTime);
                const y = getYear(isoTime);
                const m = getMonth(isoTime);
                const d = getDate(isoTime);
                const key = y+''+m+''+d;
                if (transactionMap.get(key)) {
                    let amount = transactionMap.get(key);
                    transactionMap.set(key, amount + t.amount * 1);
                } else {
                    transactionMap.set(key, t.amount * 1);
                }
            });
            response.data.deposits.forEach(dep => {
                const isoTime = parseISO(dep.createTime);
                const y = getYear(isoTime);
                const m = getMonth(isoTime);
                const d = getDate(isoTime);
                const key = y+''+m+''+d;
                if (depositMap.get(key)) {
                    let amount = depositMap.get(key);
                    const depAmount = dep.amount * 1;
                    if (dep.accountToId === id) {
                        amount += depAmount;
                    } else {
                        amount -= depAmount;
                    }
                    depositMap.set(key, amount * 1);
                } else {
                    if (dep.accountToId === id) {
                        depositMap.set(key, dep.amount * 1);
                    } else {
                        depositMap.set(key, dep.amount * -1);
                    }
                }
            });
            const date = new Date();
            const today = getDate(date);
            let month = getMonth(date);
            const year = getYear(date);
            let dayPointer = today;
            let balance = account.currentAmount;
            let days = [dayPointer];
            let balances = [account.currentAmount];
            for (var i = chartInterval; i > 0; i--) {
                const key = year+''+month+''+(dayPointer);
                if (transactionMap.get(key)) {
                    balance += (transactionMap.get(key) * 1);
                }
                if (depositMap.get(key)) {
                    balance -= (depositMap.get(key) * 1);
                }
                dayPointer--;
                // zero day bug
                if (dayPointer < 1) {
                    dayPointer = getDaysInMonth(--month);
                }
                days = [dayPointer, ...days];
                balances = [balance, ...balances];
                
            }
            const chartData = {
                labels: days,
                datasets: [{
                    label: 'Balance over last ' + chartInterval + ' days',
                    data: balances,
                    fill: false,
                    borderColor: '#42A5F5',
                    tension: .4
                }]
            }
            setChartData(chartData);
            setLoadingChart(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, chartInterval])

    React.useEffect(() => {
        setLoadingTransactions(true);
        let url = "/transactions/account/" + id;
        if (!allTransactions) {
            url = url + "/month";
        }
        axiosInstance.current.get(url).then((response) => {
            if (response.data) {
                response.data.forEach(t => {
                    t.ISOtime = t.createTime;
                    t.createTime = format(parseISO(t.createTime), "MMMM dd, yyyy h:mma");
                    if (t.updatedTime) {
                        t.updatedTime = format(parseISO(t.updatedTime), "MMMM dd, yyyy h:mma");
                    }
                });
                setTransactions(response.data);
            }
        })
        .catch((err) => {
            displayToast(false, "Unable to get account transactions.");
        })
        .finally(() => {
            setLoadingTransactions(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag, allTransactions])

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/accounts/" + id).then((response) => {
            if (response.data) {
                setAccount(response.data);
            }
        })
        .catch((err) => {
            displayToast(false, "Unable to retrieve details.");
        })
        .finally(() => {
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag]);

    // refactor into account details
    React.useEffect(() => {
        setLoadingDeposits(true);
        axiosInstance.current.get("/accounts/" + id + "/deposits").then(response => {
            if (response.data) {
                response.data.forEach(d => {
                    d.ISOtime = d.createTime;
                    d.createTime = format(parseISO(d.createTime), "MMMM dd, yyyy h:mma");
                })
                setDeposits(response.data);
            }
        }).finally(() => {
            setLoadingDeposits(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag])

    return (
        <>
        {loading ?
            <ProgressWrapper />
        :
        <>
            <CreateEditAccount visible={editVisible} account={account} displayToast={displayToast} hide={toggleEdit} />
            <CreateEditTransaction visible={createTransactionVisible} account={account} hide={toggleTransaction} displayToast={displayToast} />
            <CreateEditDeposit visible={depositVisible} account={account} displayToast={displayToast} hide={toggleDeposit} />
            <TransferComponent visible={transferVisible} account={account} displayToast={displayToast} hide={toggleTransfer} />
            <CreditPaymentComponent visible={paymentVisible} account={account} displayToast={displayToast} hide={togglePayment} />
            <Toast ref={toast} />
            <nav className="mt20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item" onClick={() => navigate("/accounts")}>Accounts</li>
                    <li className="breadcrumb-item active">{account.name}</li>
				</ol>
			</nav>
            <div className="flex-col">
                <div className="title flex-row">
                    <div>
                        {account.name}
                        {account.creditAccount ? <Badge value="Credit" severity="info" style={{ marginLeft: 10 }} /> : <></> }
                        {account.currentAmount < 0 ? <Badge value="Overdrawn" severity="danger" style={{ marginLeft: 10 }} /> : <></>}
                    </div>
                    <div className="favorite-wrapper ml20" onClick={() => toggleFavorite()} data-pr-tooltip="Favorite">
                        {account.favorite ? 
                        <FontAwesomeIcon icon={solidStar} className="favorite" />
                        :
                        <FontAwesomeIcon icon={faStar} className="non-favorite" />
                        }
                    </div>
                    <Tooltip target=".option-wrapper" mouseTrack mouseTrackLeft={10} />
                    <div className="option-wrapper" onClick={(e) => op.current.toggle(e)} data-pr-tooltip="Options">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </div>
                    <OverlayPanel ref={op} id="options-overlay" className="options-overlaypane">
                        <div className="flex-col start">
                            <Button label="Edit" className="p-button-text p-button-plain" onClick={toggleEdit}/>
                            <Button label="Delete" className="p-button-text p-button-plain" onClick={confirm} />
                            {account.creditAccount ? 
                                <Button label="Make Payment" className="p-button-text p-button-plain" onClick={togglePayment} />
                            :
                                <Button label="Deposit" className="p-button-text p-button-plain" onClick={toggleDeposit}/>
                            }
                            <Button label="Transfer" className="p-button-text p-button-plain" onClick={toggleTransfer}/>
                            <Button label="Export All Transactions" className="p-button-text p-button-plain" onClick={confirmExportAll} />
                            <Button label="Export This Month's Transactions" className="p-button-text p-button-plain" onClick={confirmExportMonth} />
                        </div>
                    </OverlayPanel>
                </div>
                <div className="secondary-title">
                    {account.description}
                </div>
                {account.creditAccount ?
                    <div className="secondary-title">
                        Available credit: ${account.currentAmount?.toFixed(2)}
                    </div>
                :
                    <div className="secondary-title">
                        Balance: ${account.currentAmount?.toFixed(2)}
                    </div>
                }
                {loadingChart ?
                    <ProgressWrapper />
                :
                    <div className="flex-col align-center">
                        <Dropdown options={intervalItems} value={chartInterval} onChange={(e) => setChartInterval(e.value)} />
                        <Chart type="line" data={chartData} style={{ width: '50%' }} />
                    </div>
                }
            </div>
            {loadingTransactions ?
                <ProgressWrapper />
            :
                <>
                <hr />
                {allTransactions ?
                    <div className="audit-details">View This Month's Transactions</div>
                :
                    <div className="audit-details">View All Transactions</div>
                }
                <InputSwitch checked={allTransactions} onChange={(e) => setAllTransactions(e.value)} />
                <div className="secondary-title">Transactions</div>
                <Button style={{ marginBottom: 10 }} className="p-button-outlined" icon="pi pi-plus" label="Create Transaction" onClick={() => {setCreateTransactionVisible(true)}} />
                <TransactionList transactions={transactions} flipFetchFlag={flipFetchFlag} displayToast={displayToast} />
                </>
            }
            <hr />
            {loadingDeposits ? 
                <ProgressWrapper />
            :
                <>
                {account.creditAccount ?
                    <div className="secondary-title mt20">Payments</div>
                :
                    <div className="secondary-title mt20">Deposits / Transfers / Payments</div>
                }
                <DepositListComponent deposits={deposits} flipFetchFlag={flipFetchFlag} displayToast={displayToast} />
                </>
            }
        </>
        }
        </>
    )
}