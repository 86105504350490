import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStar, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { faStar as solidStar } from '@fortawesome/pro-solid-svg-icons';
import { useAxios } from '../../utils/hooks.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import { format, parseISO } from 'date-fns';
import ProgressWrapper from '../../components/other/ProgressWrapper';
import TransactionList from '../../components/transactions/TransactionList';
import CreateEditPayee from '../../components/payees/CreateEditPayee';
import CreateEditTransaction from '../../components/transactions/CreateEditTransaction';

export default function PayeeDetail() {
    const { id } = useParams();
    const [ loading, setLoading ] = React.useState(true);
    const [ loadingTransactions, setLoadingTransactions ] = React.useState(false);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ payee, setPayee ] = React.useState([]);
    const [ transactions, setTransactions ] = React.useState([]);
    const [ editVisible, setEditVisible ] = React.useState(false);
    const [ createTransactionVisible, setCreateTransactionVisible ] = React.useState(false);
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const toast = React.useRef(null);
    const op = React.useRef(null);

    const displayToast = (success, msg) => {
        if (success) {
            toast.current.show({severity: 'success', summary: 'Success!', detail: msg})
        } else {
            toast.current.show({severity: 'error', summary: 'Error!', detail: msg})
        }
        flipFetchFlag();
    }

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    const toggleEdit = () => {
		setEditVisible(!editVisible);
	}

    const toggleFavorite = () => {
        axiosInstance.current.put("/payees/" + payee.payeeId).finally(() => {
			flipFetchFlag();
		});
    }

    const toggleTransaction = () => {
        setCreateTransactionVisible(!createTransactionVisible);
        flipFetchFlag();
    }

    const accept = () => {
        axiosInstance.current.delete("/payees/" + id)
            .then(() => {
                navigate("/payees");
            })
            .catch(() => {
                toast.current.show({severity: 'error', summary: 'Error!', details: 'Unable to delete payee'});
            })
    };

    const reject = () => {
    };

    const confirm = (e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this payee?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        })
    }

    const exportAll = () => {
		axiosInstance.current.get("/reports/payee/" + payee.categoryId + "/all").then(() => {
			toast.current.show({ severity: 'success', summary: 'Sent', detail: 'Export generated!'});
		})
		.catch(() => {
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Export failed.'});
		})
	}
	
	const exportMonth = () => {
		axiosInstance.current.get("/reports/payee/" + payee.categoryId + "/month").then(() => {
			toast.current.show({ severity: 'success', summary: 'Sent', detail: 'Export generated!'});
		})
		.catch(() => {
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Export failed.'});
		})
	}

    const confirmExportAll = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Export all transactions to contact email?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-primary',
			accept: exportAll,
			reject
		})
	}
	
	const confirmExportMonth = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Export this month's transactions to contact email?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-primary',
			accept: exportMonth,
			reject
		})
	}

    React.useEffect(() => {
        setLoadingTransactions(true);
        axiosInstance.current.get("/transactions/payee/" + id + "/month").then((response) => {
            if (response.data) {
                response.data.forEach(t => {
                    t.ISOtime = t.createTime;
                    t.createTime = format(parseISO(t.createTime), "MMMM dd, yyyy h:mma");
                    if (t.updatedTime) {
                        t.updatedTime = format(parseISO(t.updatedTime), "MMMM dd, yyyy h:mma");
                    }
                });
                setTransactions(response.data);
            }
        })
        .catch((err) => {
            displayToast(false, "Unable to get payee transactions.");
        })
        .finally(() => {
            setLoadingTransactions(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag])

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/payees/" + id).then((response) => {
            if (response.data) {
                setPayee(response.data);
            }
        })
        .catch((err) => {
            displayToast(false, "Unable to retrieve details.");
        })
        .finally(() => {
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag]);

    return (
        <>
        {loading ?
            <ProgressWrapper />
        :
        <>
            <CreateEditPayee payee={payee} visible={editVisible} hide={toggleEdit} displayToast={displayToast} />
            <CreateEditTransaction visible={createTransactionVisible} payee={payee} hide={toggleTransaction} displayToast={displayToast} />
            <Toast ref={toast} />
            <nav className="mt20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item" onClick={() => navigate("/payees")}>Payees</li>
                    <li className="breadcrumb-item active">{payee.name}</li>
				</ol>
			</nav>
            <div className="flex-col">
                <div className="title flex-row wrap">
                    <div className="mr20">
                        {payee.name}
                    </div>
                    <Tooltip target=".favorite-wrapper" mouseTrack mouseTrackLeft={10} />
                    <div className="favorite-wrapper pl20" onClick={() => toggleFavorite()} data-pr-tooltip="Favorite">
                        {payee.favorite ? 
                        <FontAwesomeIcon icon={solidStar} className="favorite" />
                        :
                        <FontAwesomeIcon icon={faStar} className="non-favorite" />
                        }
                    </div>
                    <Tooltip target=".options-wrapper" mouseTrack mouseTrackLeft={10} />
                    <div className="options-wrapper pl20" onClick={(e) => op.current.toggle(e)} data-pr-tooltip="Options">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </div>
                    <OverlayPanel ref={op} id="options-overlay" className="options-overlaypane">
                        <div className="flex-col start">
                            <Button label="Edit" className="p-button-text p-button-plain" onClick={toggleEdit}/>
                            <Button label="Delete" className="p-button-text p-button-plain" onClick={confirm} />
                            <Button label="Export All Transactions" className="p-button-text p-button-plain" onClick={confirmExportAll} />
                            <Button label="Export This Month's Transactions" className="p-button-text p-button-plain" onClick={confirmExportMonth} />
                        </div>
                    </OverlayPanel>
                </div>
                <div className="secondary-title">
                    {payee.description}
                </div>
                {payee.accountNumber ?
                    <div className="secondary-title">
                        Account Number: {payee.accountNumber}
                    </div>
                :
                <></>
                }
            </div>
            {loadingTransactions ?
                <ProgressWrapper />
            :
                <>
                <hr />
                <div className="secondary-title">Transactions</div>
                <Button style={{ marginBottom: 10 }} className="p-button-outlined" icon="pi pi-plus" label="Create Transaction" onClick={() => {setCreateTransactionVisible(true)}} />
                <TransactionList transactions={transactions} flipFetchFlag={flipFetchFlag} displayToast={displayToast} />
                </>
            }
        </>
        }
        </>
    )
}