import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Divider } from "primereact/divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/pro-light-svg-icons";

export default function Menu({ setMenuVisible }) {
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    return (
        <div className="flex-col">
            <div className="flex-col align-center">
                <Image src="logo.png" alt="Budgetish" height="100" width="100" />
                <div className="title">Budgetish</div>
                <div className="audit-details">by New Labs</div>
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Home" icon='pi pi-home' onClick={() => {navigate("/"); setMenuVisible(false);}} />
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Categories / Bills" icon='pi pi-shopping-bag' onClick={() => {navigate("/categories"); setMenuVisible(false);}} />
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Accounts" icon='pi pi-credit-card' onClick={() => {navigate("/accounts"); setMenuVisible(false);}} />
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Payees" icon='pi pi-building' onClick={() => {navigate("/payees"); setMenuVisible(false);}} />
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Transactions" icon='pi pi-money-bill' onClick={() => {navigate("/transactions"); setMenuVisible(false);}} />
            </div>
            <div>
                <Button style={{ color: '#2c3e50' }} className="p-button-outlined p-button-text p-button-lg" label="Reports" icon='pi pi-file' onClick={() => {navigate("/reports"); setMenuVisible(false);}} />
            </div>
            <Divider />
            <div>
                <div className="secondary-title">{keycloak.tokenParsed?.email}<span className="ml10 pointer" onClick={() => {navigate("/profile"); setMenuVisible(false);}}><FontAwesomeIcon icon={faArrowCircleRight} /></span></div>
            </div>
            <div style={{ marginTop: 20 }}>
                <div className="audit-details">New Labs System Status</div>
                <statuspage-widget src="https://newlabs.statuspage.io"></statuspage-widget>
            </div>
            <div className="mt30">
                <Button className="p-button-warning" icon="pi pi-sign-out" label="Logout" onClick={() => keycloak.logout()} />
            </div>
        </div>
    )
}