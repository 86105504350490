import React from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { useAxios } from '../../utils/hooks.ts';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';

export default function CreateEditDeposit({ account, visible, hide, displayToast }) {
    // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const [ currentTime, setCurrentTime ] = React.useState();
	const axiosInstance = useAxios();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: '',
            amount: 0.00,
            createTime: currentTime
        },
        validate: (data) => {
            let errors = {};

            if (!data.amount) {
                errors.amount = 'Amount is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.accountToId = account.accountId;
            axiosInstance.current.post("/accounts/deposit", data).then(() => {
                formik.resetForm();
                displayToast(true, "Deposit created!");
            })
            .catch((err) => {
                console.error(err);
                displayToast(false, "Error while creating deposit.");
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    React.useEffect(() => {
        setCurrentTime(new Date());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog header={'Add Deposit'} visible={visible} onHide={hide}>
			<div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="description" name="description" value={formik.values.description} onChange={formik.handleChange} autoFocus autoComplete="off"/>
                                <label htmlFor="description" >Description</label>
                            </span>
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} id="amount" name="amount" value={formik.values.amount} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('amount') })}/>
                                <label htmlFor="amount" className={classNames({ 'p-error': isFormFieldValid('amount') })}>Amount *</label>
                                {getFormErrorMessage('amount')}
                            </span>
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Calendar id="createTime" name="createTime" value={formik.values.createTime} onChange={formik.handleChange} showTime hourFormat="12" />
                            </span>
                        </div>
                        <div className="flex-row justify-content-center mt20">
                            <Button style={{ margin: 10 }} type="submit" label="Add Deposit" loading={saving} icon='pi pi-save'/>
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
    )
}