import React from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useAxios } from '../../../../utils/hooks.ts';

function AddGroupComponent(props) {
    const [ name, setName ] = React.useState("");
    const [ description, setDescription ] = React.useState("");
    const [ saving, setSaving ] = React.useState(false);

    const saveGroup = () => {
        setSaving(true);
        const newGroup = {
            name: name,
            description: description
        }
        axiosInstance.current.post("/groups", newGroup).then((response) => {
            props.saveGroup(true);
        })
        .catch((err) => {
            console.log(err);
            props.saveGroup(false);
        })
        .finally(() => {
            setSaving(false);
            setName("");
            setDescription("");
        })
    }

    const axiosInstance = useAxios();
    return (
        <Dialog visible={props.visible} onHide={props.hide} header="Create New Group">
            <div className="flex-col add-popup">
                <div>
                    <h4>Name</h4>
                    <InputText className="full-width" value={name} onChange={(e) => setName(e.target.value)} />
                    <h4>Description</h4>
                    <InputText className="full-width" value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
            </div>
            <hr />
            <div className="flex-row" style={{justifyContent: 'flex-end'}}>
                <Button
                    icon="pi pi-times"
                    label="Cancel"
                    onClick={props.hide}
                    className="p-button-outlined p-button-danger"
                    style={{margin: 10}}
                />
                <Button
                    icon="pi pi-save"
                    label="Save"
                    onClick={() => saveGroup()}
                    loading={saving}
                    className="p-button-outlined p-button-success"
                    style={{margin: 10}}
                />
            </div>
        </Dialog>
    )
}

export default AddGroupComponent;
