import React from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useAxios } from '../../../../utils/hooks.ts';

function InviteComponent(props) {
    const [ email, setEmail ] = React.useState("");
    const [ saving, setSaving ] = React.useState(false);
    const axiosInstance = useAxios();

    const sendInvite = () => {
        setSaving(true);
        const invite = {
            email: email,
            groupId: props.group.groupId
        };
        axiosInstance.current.post("/groups/invite", invite).then(() => {
            props.inviteSent(true);
        })
        .catch((err) => {
            console.log(err);
            props.inviteSent(false);
        })
        .finally(() => {
            setSaving(false);
            setEmail("");
        });
    }

    return (
        <Dialog visible={props.visible} onHide={props.hide} header="Send Invite to Group">
            <div className="flex-col add-popup">
                <div>
                    <h4>Email</h4>
                    <InputText className="full-width" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
            </div>
            <hr />
            <div className="flex-row" style={{justifyContent: 'flex-end'}}>
                <Button
                    icon="pi pi-times"
                    label="Cancel"
                    onClick={props.hide}
                    className="p-button-outlined p-button-danger"
                    style={{margin: 10}}
                />
                <Button
                    icon="pi pi-save"
                    label="Send"
                    onClick={() => sendInvite()}
                    loading={saving}
                    className="p-button-outlined p-button-success"
                    style={{margin: 10}}
                />
            </div>
        </Dialog>
    )
}

export default InviteComponent;
