import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { Tooltip } from 'primereact/tooltip';

function GroupDetail({ group, leaveGroup, deleteGroup }) {
    return (
        <div className="flex-row between plain-text p-10">
            <div>{group.name}</div>
            <div className="flex-row">
                <Tooltip target=".leave" mouseTrack mouseTrackLeft={10} />
                <div className="ml20 pointer leave" data-pr-tooltip="Leave Group"><FontAwesomeIcon icon={faTimes} onClick={() => leaveGroup(group.groupId)}/></div>
                <Tooltip target=".delete" mouseTrack mouseTrackLeft={10} />
                <div className="ml20 pointer delete" data-pr-tooltip="Delete Group"><FontAwesomeIcon icon={faTrashAlt} onClick={(e) => deleteGroup(group, e)}/></div>
            </div>
        </div>
    )
}

export default GroupDetail;
