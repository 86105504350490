import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';

export default function CreateEditPayee({ payee, visible, hide, displayToast }) {
     // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
	const axiosInstance = useAxios();

	const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: payee ? payee.name : '',
            description: payee ? payee.description : '',
            accountNumber: payee ? payee.accountNumber : '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Name is required.';
            }


            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.payeeId = payee?.payeeId;
            axiosInstance.current.post("/payees", data).then(() => {
                formik.resetForm();
                displayToast(true, "Payee saved!")
            })
            .catch((err) => {
                console.error(err);
                displayToast(false, "Error while saving payee.");
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog header={payee ? 'View/Edit Payee' : 'Create Payee'} visible={visible} onHide={hide}>
			<div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus autoComplete="off" className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Name *</label>
                            </span>
                            {getFormErrorMessage('name')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputText id="description" name="description" value={formik.values.description} onChange={formik.handleChange} autoComplete="off" className={classNames({ 'p-invalid': isFormFieldValid('description') })}/>
                                <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>Description</label>
                            </span>
                            {getFormErrorMessage('description')}
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="accountNumber" name="accountNumber" value={formik.values.accountNumber} onChange={formik.handleChange} autoComplete="off" />
                                <label htmlFor="accountNumber">Account Number</label>
                            </span>
                        </div>
                        <div className="flex-row justify-content-center mt20">
                            {payee?
                            <Button style={{ margin: 10 }} type="submit" label="Save Payee" loading={saving} icon='pi pi-save'/>
                            :
                            <Button style={{ margin: 10 }} type="submit" label="Create Payee" loading={saving} icon='pi pi-plus'/>
                            }
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
    )
}