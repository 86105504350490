import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAxios } from '../../utils/hooks.ts';

export default function CreateEditAccount({ account, visible, hide, displayToast }) {
     // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
	const axiosInstance = useAxios();

	const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: account ? account.name : '',
            description: account ? account.description : '',
            startingAmount: 0.00,
            currentAmount: account ? account.currentAmount : 0.00,
            creditAccount: account ? account.creditAccount : false
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Name is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            data.accountId = account?.accountId;
            axiosInstance.current.post("/accounts", data).then(() => {
                formik.resetForm();
                displayToast(true, "Account saved!");
            })
            .catch((err) => {
                console.error(err);
                displayToast(false, "Error while creating account.");
            })
            .finally(() => {
                setSaving(false);
				hide();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog header={account ? 'View/Edit Account' : 'Create Account'} visible={visible} onHide={hide}>
			<div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus autoComplete="off" className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Name *</label>
                            </span>
                            {getFormErrorMessage('name')}
                        </div>
						<div className="field m20">
                            <span className="p-float-label">
                                <InputText id="description" name="description" value={formik.values.description} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('description') })}/>
                                <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>Description</label>
                            </span>
                            {getFormErrorMessage('description')}
                        </div>
                        {account ?
						<div className="field m20">
                            <span className="p-float-label">
                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} id="currentAmount" name="currentAmount" value={formik.values.currentAmount} onValueChange={formik.handleChange}/>
                                <label htmlFor="currentAmount">Current Amount</label>
                            </span>
                        </div>
                        :
						<div className="field m20">
                            <span className="p-float-label">
                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} id="startingAmount" name="startingAmount" value={formik.values.startingAmount} onValueChange={formik.handleChange}/>
                                <label htmlFor="startingAmount">Starting Amount</label>
                            </span>
                        </div>
                        }
                        <div className="field-checkbox m20">
                            <Checkbox inputId="creditAccount" name="creditAccount" checked={formik.values.creditAccount} onChange={formik.handleChange} />
                            <label style={{ marginLeft: 10 }} htmlFor="creditAccount">Credit Account</label>
                        </div>
                        <div className="flex-row justify-content-center mt20">
                            {account ?
                            <Button style={{ margin: 10 }} type="submit" label="Save Account" loading={saving} icon='pi pi-save'/>
                            :
                            <Button style={{ margin: 10 }} type="submit" label="Create Account" loading={saving} icon='pi pi-plus'/>
                            }
                        </div>
                    </form>
                </div>
            </div>
		</Dialog>
    )
}