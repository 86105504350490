import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStar, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { faStar as solidStar } from '@fortawesome/pro-solid-svg-icons';
import { useAxios } from '../../utils/hooks.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { confirmPopup } from 'primereact/confirmpopup';
import { Chart } from 'primereact/chart';
import { format, parseISO, getDate } from 'date-fns';
import ProgressWrapper from '../../components/other/ProgressWrapper';
import TransactionList from '../../components/transactions/TransactionList';
import CreateEditCategory from '../../components/categories/CreateEditCategory';
import BillPaymentComponent from '../../components/categories/BillPayment';
import CreateEditTransaction from '../../components/transactions/CreateEditTransaction';

export default function CategoryDetail() {
    const { id } = useParams();
    const [ loading, setLoading ] = React.useState(true);
    const [ loadingTransactions, setLoadingTransactions ] = React.useState(false);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ category, setCategory ] = React.useState([]);
    const [ transactions, setTransactions ] = React.useState([]);
    const [ chartData, setChartData ] = React.useState({});
    const [ editVisible, setEditVisible ] = React.useState(false);
    const [ createTransactionVisible, setCreateTransactionVisible ] = React.useState(false);
	const [ paymentVisible, setPaymentVisible ] = React.useState(false);
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const toast = React.useRef(null);
    const op = React.useRef(null);

    const displayToast = (success, msg) => {
        if (success) {
            toast.current.show({severity: 'success', summary: 'Success!', detail: msg})
        } else {
            toast.current.show({severity: 'error', summary: 'Error!', detail: msg})
        }
        flipFetchFlag();
    }

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    const toggleEdit = () => {
		setEditVisible(!editVisible);
	}

    const toggleFavorite = () => {
        axiosInstance.current.put("/categories/" + category.categoryId).finally(() => {
			flipFetchFlag();
		});
    }

	const togglePayment = () => {
		setPaymentVisible(!paymentVisible);
        flipFetchFlag();
	}
    
    const toggleTransaction = () => {
        setCreateTransactionVisible(!createTransactionVisible);
        flipFetchFlag();
    }

    const navigateToPayee = (payeeId) => {
		navigate("/payees/" + payeeId, { replace: false });
	}

    const accept = () => {
        axiosInstance.current.delete("/categories/" + id)
            .then(() => {
                navigate("/categories");
            })
            .catch(() => {
                toast.current.show({severity: 'error', summary: 'Error!', details: 'Unable to delete category'});
            })
    };

    const reject = () => {
    };

    const confirm = (e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this category?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        })
    }

    const confirmMarkAsPaid = (e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to mark as paid? (This will not create a transaction record)",
            icon: 'pi pi-info-circle',
            accept: () => markAsPaid(),
            reject
        })
    }

    const markAsPaid = () => {
        const billPay = {
            billId: category.categoryId,
            amount: category.allowed,
            lateFee: 0
        }
        axiosInstance.current.put("/categories/pay", billPay).then(() => {
            toast.current.show({ severity: 'success', summary: "Paid!", detail: "Bill paid!"});
        })
        .catch(() => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong!'});
        })
        .finally(() => {
            flipFetchFlag();
        })
    }

    const exportAll = () => {
		axiosInstance.current.get("/reports/category/" + category.categoryId + "/all").then(() => {
			toast.current.show({ severity: 'success', summary: 'Sent', detail: 'Export generated!'});
		})
		.catch(() => {
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Export failed.'});
		})
	}
	
	const exportMonth = () => {
		axiosInstance.current.get("/reports/category/" + category.categoryId + "/month").then(() => {
			toast.current.show({ severity: 'success', summary: 'Sent', detail: 'Export generated!'});
		})
		.catch(() => {
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Export failed.'});
		})
	}

    const confirmExportAll = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Export all transactions to contact email?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-primary',
			accept: exportAll,
			reject
		})
	}
	
	const confirmExportMonth = (e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Export this month's transactions to contact email?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-primary',
			accept: exportMonth,
			reject
		})
	}

    React.useEffect(() => {
        setLoadingTransactions(true);
        axiosInstance.current.get("/transactions/category/" + id + "/month").then((response) => {
            if (response.data) {
                response.data.forEach(t => {
                    t.ISOtime = t.createTime;
                    t.createTime = format(parseISO(t.createTime), "MMMM dd, yyyy h:mma");
                    if (t.updatedTime) {
                        t.updatedTime = format(parseISO(t.updatedTime), "MMMM dd, yyyy h:mma");
                    }
                });
                setTransactions(response.data);
            }
        })
        .catch((err) => {
            displayToast(false, "Unable to get category transactions.");
        })
        .finally(() => {
            setLoadingTransactions(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag])

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/categories/" + id).then((response) => {
            if (response.data) {
                setCategory(response.data);
                const chartData = {
					labels: ['Remaining', 'Spent'],
					datasets: [{
						data: [Math.max(response.data.allowed - response.data.allocated, 0), response.data.allocated],
						backgroundColor: [
							"#42A5F5",
                    		"#66BB6A"
						]
					}]
				}
				setChartData(chartData);
            }
        })
        .catch((err) => {
            displayToast(false, "Unable to retrieve details.");
        })
        .finally(() => {
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag]);

    return (
        <>
        {loading ?
            <>
                <ProgressWrapper />
            </>
        :
            <>
            <CreateEditCategory category={category} visible={editVisible} hide={toggleEdit} displayToast={displayToast} />
            <BillPaymentComponent visible={paymentVisible} hidePayment={togglePayment} category={category} displayToast={displayToast}/>
            <CreateEditTransaction visible={createTransactionVisible} category={category} hide={toggleTransaction} displayToast={displayToast} />
            <Toast ref={toast} />
            <nav className="mt20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item" onClick={() => navigate("/categories")}>Categories</li>
                    <li className="breadcrumb-item active">{category.name}</li>
				</ol>
			</nav>
            <div className="flex-col">
                <div className="title flex-row wrap">
                    <div>
                        {category.name}
                    </div>
                    <div className="pl20">
                        {category.frequency ? <Badge value="Bill" severity="info" style={{ marginLeft: 10}} /> : <></>}
                        {category.frequency && getDate(new Date()) < category.day && !category.pastDue && !category.paidThisMonth ? <Badge value="Due" severity="success" style={{ marginLeft: 10}} /> : <></>}
                        {category.frequency && category.pastDue ? <Badge value="Past due" severity="danger" style={{ marginLeft: 10 }} /> : <></>}
                        {category.allowed - category.allocated < 0 ? <Badge value="Overbudget" severity="danger" style={{ marginLeft: 10}} /> : <></>}
                    </div>
                    <Tooltip target=".favorite-wrapper" mouseTrack mouseTrackLeft={10} />
                    <div className="favorite-wrapper pl20" onClick={() => toggleFavorite()} data-pr-tooltip="Favorite">
                        {category.favorite ? 
                        <FontAwesomeIcon icon={solidStar} className="favorite" />
                        :
                        <FontAwesomeIcon icon={faStar} className="non-favorite" />
                        }
                    </div>
                    <Tooltip target=".options-wrapper" mouseTrack mouseTrackLeft={10} />
                    <div className="options-wrapper pl20" onClick={(e) => op.current.toggle(e)} data-pr-tooltip="Options">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </div>
                    <OverlayPanel ref={op} id="options-overlay" className="options-overlaypane">
                        <div className="flex-col start">
                            <Button label="Edit" className="p-button-text p-button-plain" onClick={toggleEdit}/>
                            <Button label="Delete" className="p-button-text p-button-plain" onClick={confirm} />
                            {category.frequency && !category.paidThisMonth ? 
                                <>
                                <Button label="Make Payment" className="p-button-text p-button-plain" onClick={togglePayment} />
                                <Button label="Mark As Paid" className="p-button-text p-button-plain" onClick={confirmMarkAsPaid} />
                                </>
                            :
                                <></>
                            }
                            {category.payeeId ?
                                <Button label="View Payee" className="p-button-text p-button-plain" onClick={() => navigateToPayee(category.payeeId)} />	
                            :
                                <></>
                            }
                            <Button label="Export All Transactions" className="p-button-text p-button-plain" onClick={confirmExportAll} />
                            <Button label="Export This Month's Transactions" className="p-button-text p-button-plain" onClick={confirmExportMonth} />
                        </div>
                    </OverlayPanel>
                </div>
                <div className="secondary-title">
                    {category.description}
                </div>
                {category.frequency ?
                    <>
                        {category.paidThisMonth ?
                            <>
                                <div className="secondary-title">
                                    <div>Amount paid this month: ${category.billHistory.amount.toFixed(2)}</div>
                                    {category.billHistory.lateFee ? 
                                        <div>Late fee: ${category.billHistory.lateFee.toFixed(2)}</div>
                                    :
                                        <></>
                                    }
                                </div>
                            </>
                        :
                            <>
                                <div className="secondary-title">
                                    <div>Amount due: ${category.allowed.toFixed(2)}</div>
                                </div>
                                
                            </>
                        }
                        {category.day > 0 ?
                        <div className="secondary-title">
                            <div>Date due: {format(new Date(), 'MMMM') + ` ` + category.day}</div>
                        </div>
                        :
                        <></>
                        }
                        
                    </>
                :
                    <>
                        <div className="secondary-title">
                            Budgeted: ${category.allowed?.toFixed(2)}
                        </div>
                        <div className="secondary-title">
                            Remaining: ${(category.allowed - category.allocated).toFixed(2)}
                        </div>
                        <div className="secondary-title">
                            Spent this month: ${category.allocated?.toFixed(2)}
                        </div>
                        <div className="flex-row center">
                            <Chart type="pie" data={chartData} style={{width: '30%' }} />
                        </div>
                    </>
                    
                }
            </div>
            {loadingTransactions ?
                <ProgressWrapper />
            :
                <>
                <hr />
                <div className="secondary-title">Transactions</div>
                {!category.frequency && <Button style={{ marginBottom: 10 }} className="p-button-outlined" icon="pi pi-plus" label="Create Transaction" onClick={() => {setCreateTransactionVisible(true)}} />}
                <TransactionList transactions={transactions} flipFetchFlag={flipFetchFlag} displayToast={displayToast} />
                </>
            }
            </>
        }
        </>
    )
}