import * as React from 'react';
import { useAxios } from '../../../../utils/hooks.ts';
import { confirmPopup } from 'primereact/confirmpopup';
import { InputText } from 'primereact/inputtext';
import TagDisplay from '../../../../components/tags/TagDisplay';
import { Toast } from 'primereact/toast';

export default function TagComponent(props) {
	const [ tags, setTags ] = React.useState([]);
	const [ tagText, setTagText ] = React.useState("");
	const [ fetchTagsFlag, setFetchTagsFlag ] = React.useState(false);
	const axiosInstance = useAxios();
	const toast = React.useRef(null);

	const handleTagBlur = () => {
        if (tagText === '') {return;}
        const newTag = {
            text: tagText
        };
        axiosInstance.current.post("/transactions/tags", newTag).catch((err) => {
			preferenceSaved(false);
        })
        .finally(() => {
            setTagText("")
            flipFetchTagsFlag();
        })
    }

	const handleTagSelect = (tag, e) => {
		confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete " + tag.text + "?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => confirmTagSelect(tag),
            reject: () => rejectTagSelect(tag)
        }) 
    }

	const confirmTagSelect = (tag) => {
		axiosInstance.current.put("/transactions/tags", tag).catch((err) => {
			preferenceSaved(false);
        })
        .finally(() => {
            flipFetchTagsFlag();
        })
	}

	const flipFetchTagsFlag = () => {
        setFetchTagsFlag(!fetchTagsFlag);
    }

	const rejectTagSelect = (tag) => {
		setTags([...tags, tag]);
	}

	const preferenceSaved = (success) => {
		if (success) {
			toast.current.show({
				severity: 'success',
				summary: 'Saved!'
			});
		} else {
			toast.current.show({
				severity: 'error',
				summary: 'Oops!',
				detail: 'Unable to save preference'
			});
		}
	}

	React.useEffect(() => {
        axiosInstance.current.get("/transactions/tags").then((response) => {
            if (response.data) {
                setTags(response.data);
            }
        });
	// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchTagsFlag])

	return (
		<React.Fragment>
			<Toast ref={toast} />
			<div className="title">
				Tags
			</div>
			<div>
				<div className="flex-row between plain-text pt10">
					<div>New Tag:</div>
					<InputText value={tagText} onChange={(e) => setTagText(e.target.value)} onBlur={handleTagBlur} />
				</div>
				<div>
					<TagDisplay tags={tags} selectTag={handleTagSelect} removable={true}/>
				</div>
			</div>
		</React.Fragment>
	)
}