import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { useNavigate } from "react-router-dom";
import { getDate } from 'date-fns';
import { useAxios } from '../../utils/hooks.ts';
import { confirmPopup } from 'primereact/confirmpopup';
import CreateEditCategory from "./CreateEditCategory";

export default function CategoryList({ categories, flipFetchFlag, displayToast }) {
    const [ createEditVisible, setCreateEditVisible ] = React.useState(false);
    const [ selected, setSelected ] = React.useState();
    const navigate = useNavigate();
    const axiosInstance = useAxios();

    const selectRow = (e) => {
        navigate("/categories/" + e.value.categoryId);
    }

    const toggle = () => {
        setCreateEditVisible(false);
        flipFetchFlag();
    }

    const categoryAllowedBody = (rowData) => {
        return <div>{rowData.allowed.toFixed(2)}</div>
    }

    const categoryRemainingBody = (rowData) => {
        if (rowData.frequency && rowData.paidThisMonth) {
            return <div>0.00</div>
        } else {
            return <div>{(rowData.allowed - rowData.allocated).toFixed(2)}</div>
        }
    }

    const categoryStatus = (c) => {
        return (
            <>
            {c.frequency && (getDate(new Date()) < c.day || c.day === 0) && !c.pastDue && !c.paidThisMonth ? <Badge value="Due" severity="info" style={{ marginLeft: 10}} /> : c.frequency && c.paidThisMonth ? <Badge value="Paid" severity="success" style={{ marginLeft: 10 }} /> : <></>}
			{c.frequency && c.pastDue ? <Badge value="Past due" severity="danger" style={{ marginLeft: 10 }} /> : <></>}
			{c.allowed - c.allocated < 0 ? <Badge value="Overbudget" severity="danger" style={{ marginLeft: 10}} /> : !c.frequency ? <Badge value="OK" severity="success" style={{ marginLeft: 10 }} /> : <></>}
            </>
        )
    }

    const viewEditBody = (rowData) => {
        return (
            <Button className="p-button-text" label="View/Edit" icon="pi pi-pencil" onClick={() => {
                setSelected(rowData);
                setCreateEditVisible(true);
            }} />
        )
    }

    const deleteBody = (rowData) => {
        return <Button className="p-button-text p-button-danger" label="Delete" icon="pi pi-trash" onClick={(e) => confirmDeleteCategory(rowData, e)} />
    }

    const deleteCategory = (category) => {
        axiosInstance.current.delete("/categories/" + category.categoryId)
            .then(() => {
                flipFetchFlag();
            })
            .catch(() => {
                displayToast(false, "Unable to delete category.");
            })
    };

    const reject = () => {
    };

    const confirmDeleteCategory = (category, e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this category?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deleteCategory(category),
            reject
        })
    }

    return (
        <>
            <CreateEditCategory category={selected} visible={createEditVisible} hide={toggle} displayToast={displayToast} />
            <div className="mt20">
                <Button style={{ marginBottom: 10 }} className="p-button-outlined" icon="pi pi-plus" label="Create Category / Bill" onClick={() => {setCreateEditVisible(true)}} />
                <div className="secondary-title mt20">Recurring Categories / Bills</div>
                <DataTable
                    value={categories.filter(c => c.frequency !== 'ONCE')}
                    rows={10}
                    paginator
                    responsiveLayout="stack"
                    size="small"
                    dataKey="categoryId"
                    selectionMode="single"
                    onSelectionChange={(e) => selectRow(e)}
                >
                    <Column field="name" header="Name" sortable></Column>
                    <Column body={categoryAllowedBody} header="Allowed" sortable></Column>
                    <Column body={categoryRemainingBody} header="Remaining" sortable></Column>
                    <Column body={categoryStatus}></Column>
                    <Column body={viewEditBody}></Column>
                    <Column body={deleteBody}></Column>
                </DataTable>

                <div className="secondary-title mt20">One Time Bills</div>
                <DataTable
                    value={categories.filter(c => c.frequency === 'ONCE' && c.allocated !== c.allowed)}
                    rows={10}
                    paginator
                    responsiveLayout="stack"
                    size="small"
                    dataKey="categoryId"
                    selectionMode="single"
                    onSelectionChange={(e) => selectRow(e)}
                >
                    <Column field="name" header="Name" sortable></Column>
                    <Column body={categoryAllowedBody} header="Allowed" sortable></Column>
                    <Column body={categoryRemainingBody} header="Remaining" sortable></Column>
                    <Column body={categoryStatus}></Column>
                    <Column body={viewEditBody}></Column>
                    <Column body={deleteBody}></Column>
                </DataTable>
            </div>
        </>
    )
}