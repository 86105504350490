import React from "react";
import { useAxios, getFeatureStatus } from '../../utils/hooks.ts';
import { Toast } from 'primereact/toast';
import { format, parseISO } from 'date-fns';
import CategoryList from "../../components/categories/CategoryList";
import AccountList from "../../components/accounts/AccountList";
import TransactionList from "../../components/transactions/TransactionList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from "primereact/progressspinner";
import MessageDisplay from "../../components/messages/MessageDisplay";
import { sortFunction } from "../../utils/functions";

export default function HomePage() {
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ categories, setCategories ] = React.useState([]);
    const [ loadingCategories, setLoadingCategories ] = React.useState(true);
    const [ accounts, setAccounts ] = React.useState([]);
    const [ loadingAccounts, setLoadingAccounts ] = React.useState(true);
    const [ transactions, setTransactions ] = React.useState([]);
    const [ fundsAvailable, setFundsAvailable ] = React.useState(0);
    const [ remainingBills, setRemainingBills ] = React.useState(0);
    const [ fundsChartData, setFundsChartData ] = React.useState({});
    const [ remainingChartData, setRemainingChartData ] = React.useState({});
    const [ feature, setFeature ] = React.useState(false);
    const axiosInstance = useAxios();
    const toast = React.useRef(null);
    const navigate = useNavigate();
    
    const displayToast = (success, msg) => {
        if (success) {
            toast.current.show({severity: 'success', summary: 'Success!', detail: msg})
        } else {
            toast.current.show({severity: 'error', summary: 'Error!', detail: msg})
        }
    }

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    React.useEffect(() => {
        getFeatureStatus(window.HOMEPAGE_OVERVIEW_KEY).then((data) => {
            setFeature(data.enabled);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        axiosInstance.current.get("/categories").then((response) => {
            if (response.data) {
                let remaining = 0.00;
                let billNames = [];
                let billAmounts = [];
                let backgroundColors = ['#28a745'];
                response.data.filter(c => c.frequency === 'MONTH' && !c.paidThisMonth).forEach(c => {
                    remaining += c.allowed
                    billNames.push(c.name);
                    billAmounts.push(c.allowed);
                });
                setRemainingBills(remaining);
                setCategories(response.data.filter(c => c.favorite).sort(sortFunction));
                let remainingChartData = {
                    labels: billNames,
                    datasets: [{
                        data: billAmounts,
                        backgroundColor: backgroundColors
                    }]
                }
                setRemainingChartData(remainingChartData);
            }
        })
        .catch((err) => {
            console.log(err)
            displayToast(false, "Unable to get categories");
        })
        .finally(() => {
            setLoadingCategories(false);
        })
        axiosInstance.current.get("/accounts").then((response) => {
            if (response.data) {
                let accountNames = [];
                let accountAmounts = [];
                let backgroundColors = ['#007bff'];
                let funds = 0.00;
                response.data.filter(a => !a.creditAccount).forEach(a => {
                    funds += a.currentAmount
                    if (a.currentAmount > 0) {
                        accountNames.push(a.name);
                        accountAmounts.push(a.currentAmount);
                    }
                });
                setFundsAvailable(funds);
                setAccounts(response.data.filter(a => a.favorite));
                let fundsChartData = {
                    labels: accountNames,
                    datasets: [{
                        data: accountAmounts,
                        backgroundColor: backgroundColors
                    }]
                }
                setFundsChartData(fundsChartData);
            }
        })
        .catch((err) => {
            console.log(err);
            displayToast(false, "Unable to get accounts");
        })
        .finally(() => {
            setLoadingAccounts(false);
        })
        axiosInstance.current.get("/transactions/recent").then((response) => {
            if (response.data) {
                response.data.forEach(t => {
                    t.ISOtime = t.createTime;
                    t.createTime = format(parseISO(t.createTime), "MMMM dd, yyyy h:mma");
                    if (t.updatedTime) {
                        t.updatedTime = format(parseISO(t.updatedTime), "MMMM dd, yyyy h:mma");
                    }
                });
                setTransactions(response.data);
            }
        })
        .catch(() => {
            displayToast(false, "Unable to get recent transactions");
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag])

    return (
        <div>
            <Toast ref={toast} />
            <MessageDisplay />
            {feature ?
            <div>
                <div className="flex-row around full-width">
                    <div style={{ textAlign: 'center'}}>
                        <div className="title mt20">
                            Funds Available
                        </div>
                        <div className="audit-details">
                            Non-credit accounts
                        </div>
                        <div className="secondary-title mt20">
                            ${fundsAvailable.toFixed(2)}
                        </div>
                        {loadingAccounts ?
                        <ProgressSpinner />
                        :
                        <>
                        <div>
                            <Chart type="pie" data={fundsChartData} />
                        </div>
                        </>
                        }
                    </div>
                    <div style={{ textAlign: 'center'}}>
                        <div className="title mt20">
                            Remaining Bills
                        </div>
                        <div className="secondary-title mt20">
                            ${remainingBills.toFixed(2)}
                        </div>
                        {loadingCategories ?
                        <ProgressSpinner />
                        :
                        <div>
                            <Chart type="pie" data={remainingChartData} />
                        </div>
                        }
                    </div>
                </div>
                <div>
                    <div style={{ textAlign: 'center'}}>
                        <div className="title mt20">
                            Projected End of Month
                        </div>
                        <div className={classNames({'secondary-title mt20': true, 'negative-balance': (fundsAvailable - remainingBills) < 0})}>
                            ${(fundsAvailable - remainingBills).toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
            :
            <></>
            }
            <div className="title mt20 pointer" onClick={() => navigate("/categories")}>
                Favorite Categories <FontAwesomeIcon icon={faArrowCircleRight}/>
            </div>
            <CategoryList categories={categories} flipFetchFlag={flipFetchFlag} displayToast={displayToast} />
            <div className="title mt20 pointer" onClick={() => navigate("/accounts")}>
                Favorite Accounts <FontAwesomeIcon icon={faArrowCircleRight} />
            </div>
            <AccountList accounts={accounts} flipFetchFlag={flipFetchFlag} displayToast={displayToast} />
            <div className="title mt20 pointer" onClick={() => navigate("/transactions")}>
                Recent Transactions <FontAwesomeIcon icon={faArrowCircleRight} />
            </div>
            <TransactionList transactions={transactions} flipFetchFlag={flipFetchFlag} displayToast={displayToast} />
        </div>
    )
}