import * as React from 'react';
import { useAxios } from '../../../../utils/hooks.ts';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

export default function PreferencesComponent({ preferenceSaved, overdrawnAlert, setOverdrawnAlert, pastDueAlert, setPastDueAlert, recentCount, setRecentCount }) {

	const axiosInstance = useAxios();
	const toast = React.useRef(null);

	const recentDropdownItems = [
		0, 5, 10, 25, 50, 100
	]

	const handleOverdrawn = (e) => {
		const newPref = {
			key: 'overdrawn_alert',
			value: e.value
		};
		axiosInstance.current.put("/preferences", newPref)
			.then(() => {
				preferenceSaved(true);
				setOverdrawnAlert(e.value);
			})
			.catch(() => {
				preferenceSaved(false);
			})
	}

	const handlePastDue = (e) => {
		const newPref = {
			key: 'past_due_alert',
			value: e.value
		};
		axiosInstance.current.put("/preferences", newPref)
			.then(() => {
				preferenceSaved(true);
				setPastDueAlert(e.value);
			})
			.catch(() => {
				preferenceSaved(false);
			})
	}

	const handleRecentCount = (e) => {
		const newPref = {
			key: 'recent_count',
			value: e.value || 0
		};
		axiosInstance.current.put("/preferences", newPref)
			.then(() => {
				preferenceSaved(true);
				setRecentCount(e.value || 0);
			})
			.catch(() => {
				preferenceSaved(false);
			})
	}

	

	return (
		<React.Fragment>
			<Toast ref={toast} />
			<div className="title">
				Preferences
			</div>
			<div>
				<div className="flex-row between plain-text pt10">
					<div>Overdrawn alerts:</div>
					<InputSwitch checked={overdrawnAlert} onChange={handleOverdrawn} />
				</div>
				<div className="flex-row between plain-text pt10">
					<div>Past Due alerts:</div>
					<InputSwitch checked={pastDueAlert} onChange={handlePastDue} />
				</div>
				<div className="flex-row between plain-text pt10">
					<div># of Recent Transactions:</div>
					<Dropdown options={recentDropdownItems} value={recentCount} onChange={handleRecentCount} />
				</div>
			</div>
		</React.Fragment>
	)

}