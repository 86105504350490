import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Badge } from "primereact/badge";
import { useNavigate } from "react-router-dom";
import { useAxios } from '../../utils/hooks.ts';
import { confirmPopup } from 'primereact/confirmpopup';
import CreateEditAccount from "./CreateEditAccount";

export default function AccountList({ accounts, flipFetchFlag, displayToast }) {
    const [ createEditVisible, setCreateEditVisible ] = React.useState(false);
    const [ selected, setSelected ] = React.useState();
    const navigate = useNavigate();
    const axiosInstance = useAxios();

    const selectRow = (e) => {
        navigate("/accounts/" + e.value.accountId);
    }

    const toggle = () => {
        setCreateEditVisible(false);
        flipFetchFlag();
    }

    const statusBody = (rowData) => {
        if (rowData.currentAmount < 0) {
            return <Badge value="Overdrawn" severity="danger" style={{ marginLeft: 10 }} />
        }
    }

    const viewEditBody = (rowData) => {
        return (
            <Button className="p-button-text" label="View/Edit" icon="pi pi-pencil" onClick={() => {
                setSelected(rowData);
                setCreateEditVisible(true);
            }} />
        )
    }

    const deleteBody = (rowData) => {
        return <Button className="p-button-text p-button-danger" label="Delete" icon="pi pi-trash" onClick={(e) => confirmDeleteAccount(rowData, e)} />
    }

    const availableBalanceBody = (rowData) => {
        return <div>{rowData.currentAmount.toFixed(2)}</div>
    }

    const deleteAccount = (account) => {
        axiosInstance.current.delete("/accounts/" + account.accountId)
            .then(() => {
                flipFetchFlag();
            })
            .catch(() => {
                displayToast(false, "Unable to delete account.");
            })
    };

    const reject = () => {
    };

    const confirmDeleteAccount = (account, e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this account?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deleteAccount(account),
            reject
        })
    }

    return (
        <>
            <CreateEditAccount account={selected} visible={createEditVisible} hide={toggle} displayToast={displayToast} />
            <div className="mt10">
                <Button style={{ marginBottom: 10 }} className="p-button-outlined" icon="pi pi-plus" label="Create Account" onClick={() => {setCreateEditVisible(true)}} />
                <DataTable
                    value={accounts}
                    rows={10}
                    paginator
                    responsiveLayout="stack"
                    size="small"
                    selectionMode="single"
                    onSelectionChange={(e) => selectRow(e)}
                    dataKey="accountId"
                >
                    <Column field="name" header="Name" sortable></Column>
                    <Column field="description" header="Description" sortable></Column>
                    <Column body={availableBalanceBody} header="Available Balance" sortable></Column>
                    <Column body={statusBody}></Column>
                    <Column body={viewEditBody}></Column>
                    <Column body={deleteBody}></Column>
                </DataTable>
            </div>
        </>
    )
}