import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { TabView, TabPanel } from 'primereact/tabview';

export default function About() {
    const navigate = useNavigate();
    return (
        <>
            <nav className="m20">
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item active">About</li>
				</ol>
			</nav>
            <div className="title mt20">
                About Budgetish
            </div>
            <div className="mt20">
                <div className="audit-details">New Labs System Status</div>
                <statuspage-widget src="https://newlabs.statuspage.io"></statuspage-widget>
            </div>
            <TabView className="tabview-header-icon">
                <TabPanel header="Dev Notes" leftIcon="pi pi-fw pi-file">
                    <div className="flex-col wrap">
                        <div>
                            Version 1.0.4
                        </div>
                        <div>
                            <span className="audit-details">(10/16/2023)</span>
                        </div>
                        <ul>
                            <li>Filtering on transaction table</li>
                        </ul>
                        <div>
                            Version 1.0.3
                        </div>
                        <div>
                            <span className="audit-details">(08/22/2023)</span>
                        </div>
                        <ul>
                            <li>Income statement report</li>
                        </ul>
                        <div>
                            Version 1.0.2
                        </div>
                        <div>
                            <span className="audit-details">(05/26/2023)</span>
                        </div>
                        <ul>
                            <li>Adding ability to delete account</li>
                        </ul>
                        <div>
                            Version 1.0.1
                        </div>
                        <div>
                            <span className="audit-details">(05/24/2023)</span>
                        </div>
                        <ul>
                            <li>Account, category, and payee drop downs are sorted</li>
                            <li>Account, category, and payee lists are sorted</li>
                            <li>Fixed bug where bill payments were not tracked by payee in the transaction</li>
                        </ul>
                        <div>
                            Version 1.0.0
                        </div>
                        <div>
                            <span className="audit-details">(12/29/2022)</span>
                        </div>
                        <ul>
                            <li>Initial Release</li>
                        </ul>
                    </div>
                </TabPanel>
                <TabPanel header="Future Features" leftIcon="pi pi-fw pi-file">
                    <div className="flex-row wrap">
                        <ul>
                            <li>Add filtering to tables</li>
                        </ul>
                    </div>
                </TabPanel>
            </TabView>
        </>
    )

}