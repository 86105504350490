import React from 'react';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useAxios } from '../../utils/hooks.ts';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { sortFunction } from '../../utils/functions';

export default function BillPaymentComponent({ category, visible, hidePayment, displayToast}) {
    // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
    const [ accountList, setAccountList ] = React.useState([]);
    const [ currentTime, setCurrentTime ] = React.useState();
    const axiosInstance = useAxios();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: category ? category.allowed : '0.00',
            confirmation: '',
            lateFee: 0.00,
            account: null,
            paidTime: currentTime
        },
        validate: (data) => {
            let errors = {};

            if (!data.account) {
                errors.account = 'Account is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setSaving(true);
            setFormData(data);
            data.billId = category?.categoryId;
            data.payeeId = category?.payeeId;
            data.fromAccountId = data.account.accountId;
            data.paid = !data.paid;
            axiosInstance.current.put("/categories/pay", data).then(() => {
                formik.resetForm();
                displayToast(true, "Payment created!")
            })
            .catch((err) => {
                console.error(err);
                displayToast(false, "Error while saving payment.");
            })
            .finally(() => {
                setSaving(false);
				hidePayment();
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    React.useEffect(() => {
        axiosInstance.current.get("/accounts").then(response => {
            if (response.data) {
                const otherAccount = {
                    name: "Other"
                }
                const favorites = response.data.filter(a => a.favorite).sort(sortFunction);
                const others = response.data.filter(a => !a.favorite).sort(sortFunction);
                setAccountList([otherAccount, ...favorites, ...others]);
            }
        })
        setCurrentTime(new Date());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    return (
        <Dialog header="Make Bill Payment" visible={visible} onHide={hidePayment}>
            <div className="flex justify-content-center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="field m20">
                            <span className="p-float-label">
                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} id="amount" name="amount" value={formik.values.amount} onValueChange={formik.handleChange}/>
                                <label htmlFor="amount">Amount *</label>
                            </span>
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="confirmation" name="confirmation" value={formik.values.confirmation} onChange={formik.handleChange}/>
                                <label htmlFor="confirmation">Confirmation Code</label>
                            </span>
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="lateFee" name="lateFee" value={formik.values.lateFee} onChange={formik.handleChange}/>
                                <label htmlFor="lateFee">Late Fee?</label>
                            </span>
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Dropdown id="account" name="account" value={formik.values.account} onChange={formik.handleChange} options={accountList} optionLabel="name" className={classNames({ 'p-invalid': isFormFieldValid('account') })} />
                                <label htmlFor="account" className={classNames({ 'p-error': isFormFieldValid('account') })}>Account *</label>
                            </span>
                            {getFormErrorMessage('account')}
                        </div>
                        <div className="field-checkbox m20">
                            <Checkbox inputId="paid" name="paid" checked={formik.values.paid} onChange={formik.handleChange} />
                            <label style={{ marginLeft: 10 }} htmlFor="paid">Apply to Past Due?</label>
                        </div>
                        <div className="field m20">
                            <span className="p-float-label">
                                <Calendar id="paidTime" name="paidTime" value={formik.values.paidTime} onChange={formik.handleChange} showTime hourFormat="12" />
                            </span>
                        </div>
                        <div className="flex-row justify-content-center mt20">
                            {category?
                            <Button style={{ margin: 10 }} type="submit" label="Make Payment" loading={saving} icon='pi pi-save'/>
                            :
                            <Button style={{ margin: 10 }} type="submit" label="Create Category" loading={saving} icon='pi pi-plus'/>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}