import React from 'react';
import { Container } from 'react-bootstrap';
import { useKeycloak } from "@react-keycloak/web";
import { Divider } from 'primereact/divider';
import TagComponent from './components/tags/TagComponent';
import GroupsComponent from './components/groups/GroupsComponent';
import PreferencesComponent from './components/preferences/PreferencesComponent';
import { Toast } from 'primereact/toast';
import { useAxios } from '../../utils/hooks.ts';
import UserSettings from './components/user/UserSettings';

export default function ProfilePage() {
	const [ contactEmail, setContactEmail ] = React.useState('');
	const [ overdrawnAlert, setOverdrawnAlert ] = React.useState(false);
	const [ pastDueAlert, setPastDueAlert ] = React.useState(false);
	const [ recentCount, setRecentCount ] = React.useState(5);
	const { keycloak } = useKeycloak();
	const toast = React.useRef(null);
	const axiosInstance = useAxios();

	const preferenceSaved = (success) => {
		if (success) {
			toast.current.show({
				severity: 'success',
				summary: 'Saved!'
			});
		} else {
			toast.current.show({
				severity: 'error',
				summary: 'Oops!',
				detail: 'Unable to save preference'
			});
		}
	}

	React.useEffect(() => {
		axiosInstance.current.get("/preferences").then((response) => {
			const contactEmailPref = response.data.find(p => p.key === 'contact_email');
			setContactEmail(contactEmailPref?.value);
			const overdrawnPref = response.data.find(p => p.key === 'overdrawn_alert');
			setOverdrawnAlert('true' === overdrawnPref?.value);
			const pastDuePref = response.data.find(p => p.key === 'past_due_alert');
			setPastDueAlert('true' === pastDuePref?.value);
			const recentCountPref = response.data.find(p => p.key === 'recent_count');
			setRecentCount(recentCountPref?.value ? recentCountPref.value * 1 : 5);
			})
			.catch((err) => {
				toast.current.show({ severity: 'error', summary: 'Oops!', detail: 'Unable to retrieve preferences' });
			})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	return (
		<Container>
			<Toast ref={toast} />
			<div className="flex-col">
				<div className="title">
					Profile
				</div>
				<div className="secondary-title">
					{keycloak.tokenParsed?.name}
				</div>
				<div className="secondary-title">
					{keycloak.tokenParsed?.email}
				</div>
				<hr />
				<div className="flex-col col-center">
					<GroupsComponent />
					<Divider style={{ margin: 10 }} />
					<TagComponent />
					<Divider style={{ margin: 10 }}/>
					<PreferencesComponent
						preferenceSaved={preferenceSaved}
						overdrawnAlert={overdrawnAlert} 
						setOverdrawnAlert={setOverdrawnAlert}
						pastDueAlert={pastDueAlert}
						setPastDueAlert={setPastDueAlert}
						recentCount={recentCount}
						setRecentCount={setRecentCount}
					/>
					<Divider style={{ margin: 10 }}/>
					<UserSettings preferenceSaved={preferenceSaved} contactEmail={contactEmail} setContactEmail={setContactEmail} />
				</div>
			</div>
		</Container>
	)
}